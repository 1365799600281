import React, { useState, useEffect } from "react";
import Template from '../components/Template';
import './research.css';
import virusImage from '../images/virus.webp';
import antiBodies from '../images/antibodies.png';
import bones from '../images/bone.png';

import ResearchImage1 from '../images/Area_1.png';
import ResearchImage2 from '../images/Area_2.png';
import ResearchImage3 from '../images/Area_3.jpg';

const Research = () => {
  const [animationIndex, setAnimationIndex] = useState(-1);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationIndex(prevIndex => {
        const nextIndex = prevIndex + 1;
        if (nextIndex === 3) {
          clearInterval(interval); // Stop interval after all boxes have animated in
        }
        return nextIndex;
      });
    }, 100); // Adjust the delay between each box animation as needed

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  const handleBoxClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
        const vh = window.innerHeight;
        const offset = element.offsetTop - (0.25 * vh);
        window.scrollTo({
            top: offset,
            behavior: "smooth"
        });
    }
};

  return (
    <Template>
      <div className="content-container">
        <h1>Our Research</h1>
        <div className="research-page balanced-p">
          <p>Our overarching objective is to elucidate the molecular and cellular mechanisms underlying autoimmune and rheumatic diseases, with the goal of translating our findings into next-generation therapeutics.</p>
          <p>We are currently pursuing three major lines of research:</p>
          <div className="boxes-row">
            <div className={`box ${animationIndex >= 0 ? 'fade-in' : ''}`} onClick={() => handleBoxClick('microbial-triggers')}>
              <img className="small-icon" src={virusImage} alt="research_image" />
              <h2>Microbial triggers of autoimmune disease</h2>
            </div>
            <div className={`box ${animationIndex >= 1 ? 'fade-in' : ''}`} onClick={() => handleBoxClick('bcells')}>
              <img className="small-icon" src={antiBodies} alt="research_image" />
              <h2>Role of B cells and antibodies in autoimmune disease</h2>
            </div>
            <div className={`box ${animationIndex >= 2 ? 'fade-in' : ''}`} onClick={() => handleBoxClick('osteoarthritis')}>
              <img className="small-icon" src={bones} alt="research_image" />
              <h2>Innate immune inflammation in osteoarthritis</h2>
            </div>
          </div>
        </div>
        <div className="research-page-contents">
          <div className="research-container">
            <div className="research-header">
              <h3 id="microbial-triggers">Microbial triggers of autoimmune disease</h3>
            </div>
              <div className="research-image">
                <img src={ResearchImage1} alt="Microbial triggers of autoimmune disease diagram" />
              </div>
              <div className="text-box">
                <p>Autoimmune diseases affect 3-5% of the world population, yet the mechanisms underlying autoimmune initiation and progression remain incompletely defined. A major focus of our lab is elucidating microbial triggers and drivers of autoimmune disease. Our work in this area includes:</p>
                <p>(1) Defining the role of Epstein-Barr virus (EBV) in the initiation and progression of MS, SLE and other autoimmune conditions.</p>
                <p>(2) Defining the role of mucosal breaks of bacteria in the initiation and progression of RA, ANCA vasculitis and other autoimmune conditions.</p>
            </div>
          </div>
          <div className="research-container">
            <div className="research-header">
              <h3 id="bcells">Role of B cells and antibodies in autoimmune disease</h3>
            </div>
              <div className="research-image">
                <img src={ResearchImage2} alt="Role of B cells and antibodies diagram" />
              </div>
              <div className="text-box">
                <p>An overarching objective of our research is to define the mechanisms by which B cells and autoantibodies contribute to the pathogenesis of RA, SLE, MS and other autoimmune diseases.  We use single cell transcriptomics to analyze autoreactive B cells, recombinantly express their encoded antibodies, and characterize these mAbs using antigen microarrays, in vitro functional assays, and mouse models.</p>
            </div>
          </div>
          <div className="research-container-last">
            <div className="research-header">
              <h3 id="osteoarthritis">Innate immune inflammation in osteoarthritis</h3>
            </div>
              <div className="research-image">
                <img src={ResearchImage3} alt="Innate immune inflammation in osteoarthritis diagram" />
              </div>
              <div className="text-box">
                <p>OA is the most common form of arthritis.  Unlike RA, OA is not an autoimmune disorder and has been widely believed to result from ‘wear and tear’.  However, findings from our laboratory and others are revealing a key role for innate immune inflammation in the pathogenesis of OA.  Our lab is defining the innate immune mechanisms that mediate OA.</p>
            </div>  
          </div>
        </div>
      </div>
    </Template>
  );
};

export default Research;
