import React from "react";
import './teamcard.css';


const TeamCard = ({ name, picture, position, email }) => {
  return (
    <div className="team-card">
        <div className="image-cropper">
            <img src={picture} alt={`${name}'s profile`} className="profile-pic" />
        </div>
        <div className="card-content">
            <p className="p-bigger">{name}</p>
            <p>{position}</p>
            <p><a href={`mailto:${email}`} style={{ textDecoration: 'none', color: '#6e9fc1' }}>{email}</a></p>

        </div>
    </div>
  );
};

export default TeamCard;