import React, { useState, useEffect } from 'react';
import Template from '../components/Template';
import './publications.css';
import PubCard from '../components/PubCard'

import Ghosh from '../pubs/Ghosh_ACPA_RMD_Open_2023.pdf'
import Hughes from '../pubs/Hughes-Austin_PLOS_One_2023.pdf'
import James from '../pubs/James_Nat_Com_2024.pdf'

const { REACT_APP_AIRTABLE_API_TOKEN } = process.env;

const Publications = () => {
const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.airtable.com/v0/appXqP56p5bXn4bKa/Publications%202024-Present?sort%5B0%5D%5Bfield%5D=Number&sort%5B0%5D%5Bdirection%5D=desc",
          {
            headers: {
              'Authorization': `Bearer ${REACT_APP_AIRTABLE_API_TOKEN}`,
            },
          }
        );
          const responseData = await response.json();
          setData(responseData.records);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
    }, []);

    const renderNewPublications = () => {
        if (!data) {
            return null;
        }
    
        const years = Array.from(new Set(data.map(publication => publication.fields.Year)));
      
        return years.map(year => {
          const publicationsByYear = data.filter(publication => publication.fields.Year === year);
      
          return (
            <div key={year}>
              <ul className="publications-list"><b>{year}</b>
                {publicationsByYear.map((publication, index) => (
                  <p key={index}>{publication.fields.Number}. {publication.fields.Authors}. <a target="_blank" rel="noopener noreferrer" href={publication.fields.PubMed}>{publication.fields.Title}</a>. <i>{publication.fields.Journal}</i>. {publication.fields.Year}; {publication.fields.DOI_PMID}. <a target="_blank" rel="noopener noreferrer" href={publication.fields.Pdf[0].url}>Download PDF file.</a></p>
                ))}
              </ul>
            </div>
          );
        });
    };
    

  return (
    <Template>
      <div className="featured-publications-container">
        <h1 className="center s">Featured Publications</h1>
          <PubCard/>
      </div>
      <div className="publications-container">
        <h1 className="center">All Publications</h1>
        {renderNewPublications()}
        <ul className="publications-list"><b>2023</b>
          <p>265. Ghosh N, Reid P, Aude CA, Kirschman J, Goodman S, Bykerk VP, Lakhanpal A, Rajesh D, Chan KK, Robinson WH, Bass AR. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/PMC10314674/">Anticitrullinated peptide antibody epitope expansion and the HLA DRB1 'shared epitope' are less common in seropositive checkpoint inhibitor-induced inflammatory arthritis than in longstanding rheumatoid arthritis</a>. <i>RMD Open</i>. 2023; 9(2):e003012.  PMCID: PMC10314674. <a target="_blank" rel="noopener noreferrer" href={Ghosh}>Download PDF file.</a></p>
          <p>264. Hughes-Austin JM, Katz R, Majka DS, Criqui MH, Robinson WH, Firestein GS, Hundley WG, Ix JH. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/PMC10597499/"> Serum reactivity to citrullinated protein/peptide antigens and left ventricular structure and function in the Multi-Ethnic Study of Atherosclerosis (MESA)</a>. <i>PLoS One</i>. 2023;18(10):e0291967.  PMCID: PMC10597499 <a target="_blank" rel="noopener noreferrer" href={Hughes}>Download PDF file.</a></p>
          <p>263. James EA, Holers VM, Iyer R, Prideaux EB, Rao NL, Rims C, Muir VS, Posso SE, Bloom MS, Zia A, Elliott SE, Adamska JZ, Ai R, Brewer RC, Seifert JA, Moss L, Barzideh S, Demoruelle MK, Striebich CC, Okamoto Y, Sainbayar E, Crook AA, Peterson RA, Vanderlinden LA, Wang W, Boyle DL, Robinson WH, Buckner JH, Firestein GS, Deane KD. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/PMC10665556/">Multifaceted immune dysregulation characterizes individuals at-risk for rheumatoid arthritis</a>. <i>Nat Communications</i>. 2023;14(1):7637.  PMCID: PMC10665556. <a target="_blank" rel="noopener noreferrer" href={James}>Download PDF file.</a></p>
          <p>262. Baker MC, Sheth K, Lu R, Lu D, von Kaeppler EP, Bhat A, Felson DT, Robinson WH. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36987654/">Increased risk of osteoarthritis in patients with atopic disease</a>. <i>Annals of Rheumatic Diseases</i>. 2023 Jun;82(6):866-872. PMID: 36987654. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/262.pdf">Download PDF file.</a></p>
          <p>261. Baker MC, Liu Y, Lu R, Lin J, Melehani J, Robinson WH. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36939701/">Incidence of interstitial lung disease in patients with rheumatoid arthritis treated with biologic and targeted synthetic disease-modifying antirheumatic drugs.</a> <i>Journal of American Medical Association Network Open</i>. 2023 Mar 1;6(3):e233640. PMID: 36939701. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/261.pdf">Download PDF file</a>.</p>
          <p>260. Baker MC, Sheth K, Liu Y, Lu D, Lu R, Robinson WH. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36939700/">Development of osteoarthritis in adults with type 2 diabetes treated with metformin vs a sulfonylurea.</a> <i>Journal of American Medical Association Network Open</i>. 2023 Mar 1;6(3):e233646. PMID: 36939700. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/260.pdf">Download PDF file</a>.</p>
          <p>259. Um H, Jeong H, Lee B, Kim Y, Lee J, Roh JS, Lee SG, Park HR, Robinson WH, Sohn DH. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36926204/">FAT10 Induces cancer cell migration by stabilizing phosphorylated ABI3/NESH.</a> <i>Animal Cells and Systems,</i> 2023; Mar 11;27(1):53-60. PMID: 36926204. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/259.pdf">Download PDF file</a>.</p>
          <p>258. Adamska JZ, Zia A, Bloom MS, Crofford LJ, Furst DE, Goldmuntz E, Keyes-Elstein L, Mayes MD, McSweeney P, Nash RA, Pinckney A, Welch B, Love ZZ, Sullivan KM, Robinson WH. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36241361/">Myeloablative autologous haematopoietic stem cell transplantation resets the B cell repertoire to a more naïve state in patients with systemic sclerosis</a>. <i>Annals of Rheumatic Diseases</i>, 2023; 82(3):357-364. PMID: 36241361.<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/258.pdf"> Download PDF file.</a></p>
          <p>257. Kim EY, Kim JE, Kim YE, Choi B, Sohn DH, Park SO, Chung YH, Kim Y, Robinson WH, Kim YG, Chang EJ. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36882866/">Dysfunction in parkin aggravates inflammatory bone erosion by reinforcing osteoclast activity</a>. <i>Cell Biosciences</i>, 2023; 13(1):48. PMID: 36882866. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/257.pdf">Download PDF file</a>.</p>
          <p>256. Kronzer VL, Hayashi K, Yoshida K, Davis JM 3rd, McDermott GC, Huang W, Dellaripa PF, Cui J, Feathers V, Gill RR, Hatabu H, Nishino M, Blaustein R, Crowson CS, Robinson WH, Sokolove J, Liao KP, Weinblatt ME, Shadick NA, Doyle TJ, Sparks JA. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36874209/">Autoantibodies against citrullinated and native proteins and prediction of rheumatoid arthritis-associated interstitial lung disease: A nested case-control study.</a> <i>Lancet Rheumatology,</i> 2023; (2):e77-e87. PMID: 36874209; PMCID: PMC9979957. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/256.pdf">Download PDF file</a>.</p>
          <p>255. Mehta B, Goodman S, DiCarlo E, Jannat-Khah D, Gibbons JAB, Otero M, Donlin L, Pannellini T, Robinson WH, Sculco P, Figgie M, Rodriguez J, Kirschmann JM, Thompson J, Slater D, Frezza D, Xu Z, Wang F, Orange DE. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36864474/">Machine learning identification of thresholds to discriminate osteoarthritis and rheumatoid arthritis synovial inflammation</a><i>.</i> <i>Arthritis Research Therapy</i>, 2023; 25(1):31. PMID: 36864474; PMCID: PMC9979511. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/255.pdf">Download PDF file</a>.</p>
          <p>254. Lanz TV, Robinson WH, Ho PP, Steinman L. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36815946/">Roadmap for understanding mechanisms on how Epstein-Barr virus triggers multiple sclerosis and for translating these discoveries in clinical trials</a>. Clinical Translational Immunology, 2023;12(2):e1438. PMID: 36815946. PMCID: PMC9933111. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/254.pdf">Download PDF file</a>.</p>
          <p>253. Lee JA, Mikuls TR, Deane KD, Sayles HR, Thiele GM, Edison JD, Wagner BD, Feser ML, Moss LK, Kelmenson LB, Robinson WH, Payne JB. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36812865/">Serum antibodies to periodontal pathogens prior to rheumatoid arthritis diagnosis: A case-control study. Seminars</a> <i>Arthritis Rheumatism,</i> 2023; 59:152176. PMID: 36812865. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/253.pdf">Download PDF file</a>.</p>
          <p>252. Brewer RC, Lanz TV, Hale CR, Sepich-Poore GD, Martino C, Swafford AD, Carroll TS, Kongpachith S, Blum LK, Elliott SE, Blachere NE, Parveen S, Fak J, Yao V, Troyanskaya O, Frank MO, Bloom MS, Jahanbani S, Gomez AM, Iyer R, Ramadoss NS, Sharpe O, Chandrasekaran S, Kelmenson LB, Wang Q, Wong H, Torres HL, Wiesen M, Graves DT, Deane KD, Holers VM, Knight R, Darnell RB, Robinson WH, Orange DE. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36812347/">Oral mucosal breaks trigger anti-citrullinated bacterial and human protein antibody responses in rheumatoid arthritis</a>.<i> Science Translational Medicine, </i>2023; 15(684):eabq8476. PMID: 36812347; PMCID: PMC9852471. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/252.pdf">Download PDF file</a>.</p>
          <p>251. Moon JS, Younis S, Ramadoss NS, Iyer R, Sheth K, Sharpe O, Rao NL, Becart S, Carman JA, James EA, Buckner JH, Deane KD, Holers VM, Goodman SM, Donlin LT, Davis MM, Robinson WH. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36658110/">Cytotoxic CD8+ T cells target citrullinated antigens in rheumatoid arthritis.</a> <i>Nature Communications,</i> 2023; 14(1):319. PMID: 36658110; PMCID: PMC9852471. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/251.pdf">Download PDF file</a>.</p>
        </ul>
        <ul className="publications-list"><b>2022</b>
        <p>250. Casal Moura M, Thompson GE, Nelson DR, Fussner LA, Hummel AM, Jenne DE, Emerling D, Fervenza FC, Kallenberg CGM, Langford CA, McCune WJ, Merkel PA, Monach PA, Seo P, Spiera RF, St Clair EW, Ytterberg SR, Stone JH, Robinson WH, Specks U; WGET and RAVE-ITN Research Groups<a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36515151/">. Activation of a Latent Epitope Causing Differential Binding of Antineutrophil Cytoplasmic Antibodies to Proteinase 3.</a> <i>Arthritis Rheumatology</i>, 2022; art.42418. PMID: 36515151. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/250.pdf">Download PDF File</a>.</p>
          <p>249. Jahanbani S, Hansen PS, Blum LK, Bastounis EE, Ramadoss NS, Pandrala M, Kirschmann JM, Blacker GS, Love ZZ, Weissman IL, Nemati F, Tal MC, Robinson WH. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36396013/">Increased macrophage phagocytic activity with TLR9 agonist conjugation of an anti- Borrelia burgdorferi monoclonal antibody.</a> <i>Clinical Immunology</i>, 2023; 246:109180. PMID: 36396013. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/249.pdf">Download PDF file</a>.</p>
          <p>248. Holers VM, Kuhn KA, Demoruelle MK, Norris JM, Firestein GS, James EA, Robinson WH, Buckner JH, Deane KD. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36312783/">Mechanism-driven strategies for prevention of rheumatoid arthritis</a>. Rheumatology Autoimmunity, 2022; 2(3):109-119. PMID: 36312783. PMCID: PMC9610829. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/248.pdf">Download PDF file</a>.</p>
          <p>247. Chriswell ME, Lefferts AR, Clay MR, Hsu AR, Seifert J, Feser ML, Rims C, Bloom MS, Bemis EA, Liu S, Maerz MD, Frank DN, Demoruelle MK, Deane KD, James EA, Buckner JH, Robinson WH, Holers VM, Kuhn KA. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36288282/">Clonal IgA and IgG autoantibodies from individuals at risk for rheumatoid arthritis identify an arthritogenic strain of Subdoligranulum</a>. <i>Science Translational Medicine</i>, 2022; 14(668):eabn5166. scitranslmed.abn5166. PMID: 36288282; PMCID: PMC9804515. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/247.pdf">Download PDF file</a>.</p>
          <p>246. Zhao X, Younis S, Shi H, Hu S, Zia A, Wong HH, Elliott EE, Chang T, Bloom MS, Zhang W, Liu X, Lanz TV, Sharpe O, Love ZZ, Wang Q, Robinson WH. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36109004/">RNA-seq characterization of histamine-releasing mast cells as potential therapeutic target of osteoarthritis.</a> <i>Clinical Immunology</i>, 2022; 244:109117. j.clim.2022.109117. PMID: 36109004. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/246.pdf">Download PDF file</a>.</p>
          <p>245. Jung J, Lee LE, Kim H, Kim JE, Jang SH, Roh JS, Lee B, Robinson WH, Sohn DH, Pyun JC, Song JJ.<a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36032105/"> Extracellular histones aggravate autoimmune arthritis by lytic cell death</a>. <i>Frontiers Immunology</i>, 2022; 13:961197. PMID: 36032105; PMCID: PMC9410568.<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/245.pdf"> Download PDF file.</a></p>
          <p>244. Brewer RC, Robinson WH, Lanz TV. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/35871170/">SARS-CoV-2 infection of monocytes: balancing acts of antibodies and inflammasomes</a>. <i>Signal Transduction Targeted Therapy</i>, 2022; 7(1):250. PMID: 35871170; PMCID: PMC9308028. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/244.pdf">Download PDF file</a>.</p>
          <p>243. Scholz A, DeFalco J, Leung Y, Aydin IT, Czupalla CJ, Cao W, Santos D, Vad N, Lippow SM, Baia G, Harbell M, Sapugay J, Zhang D, Wu DC, Wechsler E, Ye AZ, Wu JW, Peng X, Vivian J, Kaplan H, Collins R, Nguyen N, Whidden M, Kim D, Millward C, Benjamin J, Greenberg NM, Serafini TA, Emerling DE, Steinman L, Robinso WH, Manning-Bog A. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/35507878/">Mobilization of innate and adaptive antitumor immune responses by the RNP-targeting antibody ATRC-101</a>.<i> Procedings National Acadamy of Sciences U S A</i>, 2022; 119(19):e2123483119. PMID: 35507878; PMCID: PMC9171637. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/243.pdf">Download PDF file</a>.</p>
          <p>242. Lanz TV, Brewer RC, Jahanbani S, Robinson WH. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/35441169/">Limited Neutralization of Omicron by Antibodies from the BNT162b2 Vaccination against SARS-CoV-2</a>.<i> Research Square</i>, 2022; rs.3.rs-1518378. PMID: 35441169; PMCID: PMC9016652. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/242.pdf">Download PDF file</a>.</p>
          <p>241. Li J, Zaslavsky M, Su Y, Guo J, Sikora MJ, van Unen V, Christophersen A, Chiou SH, Chen L, Li J, Ji X, Wilhelmy J, McSween AM, Palanski BA, Mallajosyula VVA, Bracey NA, Dhondalay GKR, Bhamidipati K, Pai J, Kipp LB, Dunn JE, Hauser SL, Oksenberg JR, Satpathy AT, Robinson WH, Dekker CL, Steinmetz LM, Khosla C, Utz PJ, Sollid LM, Chien YH, Heath JR, Fernandez-Becker NQ, Nadeau KC, Saligrama N, Davis MM. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/35258337/">KIR+CD8+ T cells suppress pathogenic T cells and are active in autoimmune diseases and COVID-19</a>. <i>Science</i>, 2022; 376(6590):eabi9591. PMID: 35258337; PMCID: PMC8995031. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/241.pdf">Download PDF file.</a></p>
          <p>240. Chunder R, Weier A, Mäurer H, Luber N, Enders M, Luber G, Heider T, Spitzer A, Tacke S, Becker-Gotot J, Kurts C, Iyer R, Ho PP, Robinson WH, Lanz TV, Kuerten S. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/35235454/">Antibody cross-reactivity between casein and myelin-associated glycoprotein results in central nervous system demyelination.</a>Proc Natl Acad Sci U S A. 2022 Mar; 119(10):e2117034119. doi: 10.1073/pnas.2117034119. Epub 2022 Mar 2. PMID: 35235454; PMCID: PMC8916005. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/240.pdf">Download PDF file</a>.</p>
          <p>239.  Lanz TV, Brewer RC, Jahanbani S, Robinson WH. Limited Neutralization of <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/35441169/">Omicron by Antibodies from the BNT162b2 Vaccination against SARS-CoV-2. Res Sq</a> [Preprint]. 2022 Apr 14:rs.3.rs-1518378. doi: 10.21203/rs.3.rs-1518378/v1. PMID: 35441169; PMCID: PMC9016652.. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/239.pdf">Download PDF file</a>.</p>
          <p>238.  Lanz TV, Brewer RC, Ho PP, Moon JS, Jude KM, Fernandez D, Fernandes RA, Gomez AM, Nadj GS, Bartley CM, Schubert RD, Hawes IA, Vazquez SE, Iyer M, Zuchero JB, Teegen B, Dunn JE, Lock CB, Kipp LB, Cotham VC, Ueberheide BM, Aftab BT, Anderson MS, DeRisi JL, Wilson MR, Bashford-Rogers RJM, Platten M, Garcia KC, Steinman L, Robinson WH. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/35073561/">Clonally expanded B cells in multiple sclerosis bind EBV EBNA1 and GlialCAM.</a> Nature. 2022 Mar;603(7900):321-327. doi: 10.1038/s41586-022-04432-7. Epub 2022 Jan 24. PMID: 35073561. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/238-2.pdf">Download PDF file</a>.</p>
          <p>237.  Robinson WH, Steinman L. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/35025606/">Epstein-Barr virus and multiple sclerosis.</a> <i>Science.</i> 2022 Jan 21;375(6578):264-265. doi: 10.1126/science.abm7930. Epub 2022 Jan 13. PMID: 35025606. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/237.pdf">Download PDF file</a>.</p>
        </ul>
        <ul className="publications-list"><b>2021</b>
        <p>236.  Li J, Zaslavsky M, Su Y, Sikora MJ, van Unen V, Christophersen A, Chiou SH, Chen L, Li J, Ji X, Wilhelmy J, McSween AM, Palanski BA, Aditya Mallajosyula VV, Dhondalay GKR, Bhamidipati K, Pai J, Kipp LB, Dunn JE, Hauser SL, Oksenberg JR, Satpathy AT, Robinson WH, Steinmetz LM, Khosla C, Utz PJ, Sollid LM, Heath JR, Fernandez-Becker NQ, Nadeau KC, Saligrama N, Davis MM. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/34981055/">Human KIR <sup>+</sup> CD8 <sup>+</sup> T cells target pathogenic T cells in Celiac disease and are active in autoimmune diseases and COVID-19.</a> bioRxiv. 2021 Dec 25:2021.12.23.473930. doi: 10.1101/2021.12.23.473930. Preprint. PMID: 34981055.</p>
          <p>235.  Jabado O, Maldonado MA, Schiff M, Weinblatt ME, Fleischmann R, Robinson WH, He A, Patel V, Greenfield A, Saini J, Galbraith D, Connolly SE. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/34878629/">Differential Changes in ACPA Fine Specificity and Gene Expression in a Randomized Trial of Abatacept and Adalimumab in Rheumatoid Arthritis.</a> <i>Rheumatol Ther.</i> 2021 Dec 8. doi: 10.1007/s40744-021-00404-x. Online ahead of print. PMID: 34878629. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/235.pdf">Download PDF file.</a></p>
          <p>234.  Hirotsu KE, Scott MKD, Marquez C, Tran AT, Rieger KE, Novoa RA, Robinson WH, Kwong BY, Zaba LC.  <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/34875301/">Histologic subtype of cutaneous immune-related adverse events predicts overall survival in patients receiving immune checkpoint inhibitors.</a>  <i>J Am Acad Dermatol.</i> 2021 Dec 4:S0190-9622(21)02922-4. doi: 10.1016/j.jaad.2021.11.050. Online ahead of print .PMID: 34875301. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/234.pdf">Download PDF file.</a></p>    
          <p>233.  Brewer RC, Ramadoss NS, Lahey LJ, Jahanbani S, Robinson WH, Lanz TV. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/34848871/">BNT162b2 vaccine induces divergent B cell responses to SARS-CoV-2 S1 and S2.</a> <i>Nat Immunol.</i> 2022 Jan;23(1):33-39. doi: 10.1038/s41590-021-01088-9. Epub 2021 Nov 30. PMID: 34848871. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/233.pdf">Download PDF file</a>.</p>
          <p>232.  Fechtner S, Berens H, Bemis E, Johnson RL, Guthridge CJ, Carlson NE, Demoruelle MK, Harley JB, Edison JD, Norris JA, Robinson WH, Deane KD, James JA, Holers VM.  <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/34605217/">Antibody Responses to Epstein-Barr Virus in the Preclinical Period of Rheumatoid Arthritis Suggest the Presence of Increased Viral Reactivation Cycles.</a> <i>Arthritis Rheumatol.</i> 2021 Oct 3. doi: 10.1002/art.41994. Online ahead of print. PMID: 34605217.</p>
          <p>231.  Chang SE, Feng A, Meng W, Apostolidis SA, Mack E, Artandi M, Barman L, Bennett K, Chakraborty S, Chang I, Cheung P, Chinthrajah S, Dhingra S, Do E, Finck A, Gaano A, Geßner R, Giannini HM, Gonzalez J, Greib S, Gündisch M, Hsu AR, Kuo A, Manohar M, Mao R, Neeli I, Neubauer A, Oniyide O, Powell AE, Puri R, Renz H, Schapiro J, Weidenbacher PA, Wittman R, Ahuja N, Chung HR, Jagannathan P, James JA, Kim PS, Meyer NJ, Nadeau KC, Radic M, Robinson WH, Singh U, Wang TT, Wherry EJ, Skevaki C, Luning Prak ET, Utz PJ. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/34521836/">New-onset IgG autoantibodies in hospitalized patients with COVID-19.</a> <i>Nat Commun</i>. 2021 Sep 14;12(1):5417. doi: 10.1038/s41467-021-25509-3. PMID: 34521836. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/231.pdf">Download PDF file</a>.</p>
          <p>230.  Baker MC, Mallajosyula V, Davis MM, Boyd SD, Nadeau KC, Robinson WH. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/34514750/">Effective viral vector response to SARS-CoV-2 booster vaccination in a patient with rheumatoid arthritis after initial ineffective response to messenger RNA vaccine.</a> <i>Arthritis Rheumatol</i>. 2021 Sep 12:10.1002/art.41978. doi: 10.1002/art.41978. Online ahead of print. PMID: 34514750. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/230.pdf">Download PDF file</a>.</p>
          <p>229.  Leonardi EA, Xiao M, Murray IR, Robinson WH, Abrams GD. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/34435068/">Tendon-Derived Progenitor Cells With Multilineage Potential Are Present Within Human Patellar Tendon.</a> <i>Orthop J Sports Med</i>. 2021 Aug 20;9(8):23259671211023452. doi: 10.1177/23259671211023452. eCollection 2021 Aug. PMID: 34435068. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/229.pdf">Download PDF file</a>.</p>
          <p>228.  Radtke FA, Ramadoss N, Garro A, Bennett JE, Levas MN, Robinson WH, Nigrovic PA, Nigrovic LE; for Pedi Lyme Net. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/34379528/">Serologic Response to <i>Borrelia</i> Antigens Varies with Clinical Phenotype in Children and Young Adults with Lyme Disease.</a> <i>J Clin Microbiol</i>. 2021 Oct 19;59(11):e0134421. doi: 10.1128/JCM.01344-21. Epub 2021 Aug 11. PMID: 34379528. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/228.pdf">Download PDF file</a>.</p>
          <p>227.  Won P, Kim Y, Jung H, Rim YA, Sohn DH, Robinson WH, Moon SJ, Ju JH. <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/34305925/">Pathogenic Role of Circulating Citrullinated Antigens and Anti-Cyclic Monoclonal Citrullinated Peptide Antibodies in Rheumatoid Arthritis.</a><i> Front Immunol. </i>2021 Jun 30;12:692242. doi: 10.3389/fimmu.2021.692242. eCollection 2021. PMID: 34305925. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/227.pdf">Download PDF file</a>.</p>
          <p>226.  Yoshizawa Y, Nakao T, Tsuritani K, Yamada T, Watanabe N, Chiba A, Robinson WH, Miyake S.  <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/34251306/">The importance of specific citrullinated clusterin and vimentin found in a multi-coloured bead-based citrulline-peptide array system in rheumatoid arthritis.</a> <i>Clin Exp Rheumatol.</i> 2021 Jul 8. PMID: 34251306. </p>
          <p>225. von Kaeppler EP, Wang Q, Raghu H, Bloom MS, Wong H, Robinson WH. Interleukin 4 promotes anti-inflammatory macrophages that clear cartilage debris and inhibits osteoclast development to protect against osteoarthritis. <i>Clin Immunol</i>. 2021 Jun 11;229:108784. doi: 10.1016/j.clim.2021.108784. PMID: 34126239. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/225-Interleukin.pdf">Download PDF file.</a></p>
          <p>224. Jarrell JA, Baker MC, Perugino CA, Liu H, Bloom MS, Maehara T, Wong HH, Lanz TV, Adamska JZ, Kongpachith S, Sokolove J, Stone JH, Pillai SS, Robinson WH. Neutralizing anti-IL-1 receptor antagonist autoantibodies induce inflammatory and fibrotic mediators in IgG4-related disease. <i>J Allergy Clin Immunol</i>. 2021 May 8:S0091-6749(21)00729-6. doi: 10.1016/j.jaci.2021.05.002. PMID: 33974929.<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/224-Neutralizing.pdf"> Download PDF file</a>.</p>
          <p>223. England BR, Campany M, Sayles H, Roul P, Yang Y, Ganti AK, Sokolove J, Robinson WH, Reimold AM, Kerr GS, Cannon GW, Sauer BC, Baker JF, Thiele GM, Mikuls TR. Associations of serum cytokines and chemokines with the risk of incident cancer in a prospective rheumatoid arthritis cohort. <i>Int Immunopharmacol.</i> 2021 Apr 29;97:107719. doi:10.1016/j.intimp.2021.107719. PMID: 33933845. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/223-Associations.pdf">Download PDF file.</a></p>
          <p>222. Bhamidipati K, Silberstein JL, Chaichian Y, Baker MC, Lanz TV, Zia A, Rasheed YS, Cochran JR, Robinson WH. CD52 Is Elevated on B cells of SLE Patients and Regulates B Cell Function. <i>Front Immunol</i>. 2021 Feb 4;11:626820.doi: 10.3389/fimmu.2020.626820. eCollection 2020. PMID: 33658999 PMCID: PMC7917337. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/222-CD52.pdf">Download PDF file</a>.</p>
          <p>221. Chang SE, Feng A, Meng W, Apostolidis SA, Mack E, Artandi M, Barman L, Bennett K, Chakraborty S, Chang I, Cheung P, Chinthrajah S, Dhingra S, Do E, Finck A, Gaano A, Geßner R, Giannini HM, Gonzalez J, Greib S, Gündisch M, Hsu AR, Kuo A, Manohar M, Mao R, Neeli I, Neubauer A, Oniyide O, Powell AE, Puri R, Renz H, Schapiro JM, Weidenbacher PA, Wittman R, Ahuja N, Chung HR, Jagannathan P, James J, Kim PS, Meyer NJ, Nadeau K, Radic M, Robinson WH, Singh U, Wang TT, Wherry EJ, Skevaki C, Prak ETL, Utz PJ. New-Onset IgG Autoantibodies in Hospitalized Patients with COVID-19. <i>medRxiv</i>.  In <a target="_blank" rel="noopener noreferrer" href="https://www.medrxiv.orghttps://med.stanford.edu/content/10.1101/2021.01.27.21250559v1">pre-print</a> 2021 Jan 29;2021.01.27.21250559.  doi: 10.1101/2021.01.27.21250559. PMID: 33532787. PMCID: PMC7852238. <a target="_blank" rel="noopener noreferrer" href="https://www.medrxiv.orghttps://med.stanford.edu/content/10.1101/2021.01.27.21250559v1">Download PDF file</a>.</p>
          <p>220. Ptacek J, Hawtin RE, Sun D, Louie B, Evensen E, Mittleman BB, Cesano A, Cavet G, Bingham CO, Cofield SS, Curtis JR, Danila MI, Raman C, Furie RA, Genovese MC, Robinson WH, Levesque MC, Moreland LW, Nigrovic PA, Shadick NA, O'Dell JR, Thiele GM, Clair EWS, Striebich CC, Hale MB, Khalili H, Batliwalla F, Aranow C, Mackay M, Diamond B, Nolan GP, Gregersen PK, Bridges SL. Diminished cytokine-induced Jak/STAT signaling is associated with rheumatoid arthritis and disease activity. <i>PLoS One</i>. 2021;16(1):e0244187. doi: 10.1371/journal.pone.0244187. PMID: 33444321.<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/220_Diminshed.pdf"> Download PDF file</a>.</p>
          <p>219. Wagar LE, Salahudeen A, Constantz CM, Wendel BS, Lyons MM, Mallajosyula V, Jatt LP, Adamska JZ, Blum LK, Gupta N, Jackson KJL, Yang F, Röltgen K, Roskin KM, Blaine KM, Meister KD, Ahmad IN, Cortese M, Dora EG, Tucker SN, Sperling AI, Jain A, Davies DH, Felgner PL, Hammer GB, Kim PS, Robinson WH, Boyd SD, Kuo CJ, Davis MM. Modeling human adaptive immune responses with tonsil organoids. <i>Nat Med</i>. 2021 01;27(1):125-135. doi: 10.1038/s41591-020-01145-0. PMID: 33432170. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/219_Modeling.pdf">Download PDF file</a>.</p>
        </ul>
        <ul className="publications-list"><b>2020</b>
          <p>218. Petro AD, Dougherty J, England BR, Sayles H, Duryee MJ, Hunter CD, Kremer JM, Pappas DA, Robinson WH, Curtis JR, Thiele GM, Mikuls TR. Associations between an expanded autoantibody profile and treatment responses to biologic therapies in patients with rheumatoid arthritis.  <i>Int Immunopharmacol</i>. 2020 Dec 23;91:107260. doi: 10.1016/j.intimp.2020.107260. PMID: 33360371. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/218_Associations.pdf">Download PDF file</a>.</p>
          <p>217.  Mosel MR, Rebman AW, Carolan HE, Montenegro T, Lovari R, Schutzer SE, Ecker DJ, Yang T, Ramadoss NS, Robinson WH, Soloski MJ, Eshoo MW, Aucott JN. Molecular Microbiological and Immune Characterization of a Cohort of Patients Diagnosed with Early Lyme Disease. <i>J Clin Microbiol</i>. 2020 Dec 17;59(1). doi: 10.1128/JCM.00615-20. PMID: 33087434. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/217_Molecular.pdf">Download PDF file.</a></p>
          <p>215. Xiao M, Leonardi EA, Sharpe O, Sherman SL, Safran MR, Robinson WH, Abrams GD. Soaking of Autologous Tendon Grafts in Vancomycin Before Implantation Does Not Lead to Tenocyte Cytotoxicity. <i>Am J Sports Med</i>. 2020 Sep 8:363546520951815. doi: 10.1177/0363546520951815. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/215.pdf">Downlod PDF FIle</a>.</p>
          <p>214. Kuerten S, Lanz TV, Lingampalli N, Lahey LJ, Kleinschnitz C, Mäurer M, Schroeter M, Braune S, Ziemssen T, Ho PP, Robinson WH, Steinman L. Autoantibodies against central nervous system antigens in a subset of B cell-dominant multiple sclerosis patients.<i> Proc Natl Acad Sci U S A.</i> 2020 Sep 1;117(35):21512-21518. doi: 10.1073/pnas.2011249117. Epub 2020 Aug 18.215. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/214.pdf">Downlod PDF File</a>. </p>
          <p>213. Gravallese EM, Robinson WH PRIME Time in Rheumatoid Arthritis. <i>N Engl J Med.</i> 2020 Jul 16;383(3):278-279. doi: 10.1056/NEJMe2018218. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/213.pdf">Download PDF File</a>.</p>
          <p>212.  Carmona-Rivera C, Carlucci PM, Moore E, Lingampalli N, Uchtenhagen H, James E, Liu Y, Bicker KL, Wahamaa H, Hoffmann V, Catrina AI, Thompson PR, Buckner JH, Robinson WH, Fox DA, Kaplan MJ.  Erratum for Research Article "Synovial fibroblast-neutrophil interactions promote pathogenic adaptive immunity in rheumatoid arthritis. Sci Immunol. 2020 Jan 31;5(43):eaaz9319. doi: 10.1126.<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/212.pdf"> Download PDF File</a>.</p>
          <p>211. Steinman L, Ho PP,<b> </b>Robinson WH, Utz PJ, Villoslada P.  Antigen-specific tolerance to self-antigens in protein replacement therapy, gene therapy and autoimmunity <i>Curr Opin Immunol</i>. 2019 Dec;61:46-53. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/211.pdf">Download PDF File</a>.</p>
          <p>210. Elliott SE, Kongpachith S, Lingampalli N, Adamska JZ, Cannon BJ, Blum LK, Bloom MS, Henkel M, McGeachy MJ, Moreland LW, Robinson WH. B cells in rheumatoid arthritis synovial tissues encode focused antibody repertoires that include antibodies that stimulate macrophage TNF-α production. <i>Clin Immunol</i>. 2020 Feb 5;212:108360. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/209.pdf">Downlaod PDF file</a>. </p>
          <p>209. Zaccardelli A, Liu X, Ford JA, Cui J, Lu B, Chu SH, Schur PH, Speyer CB, Costenbader KH, Robinson WH, Sokolove J, Karlson EW, Camargo CA Jr, Sparks JA. Elevated anti-citrullinated protein antibodies prior to rheumatoid arthritis diagnosis and risks for chronic obstructive pulmonary disease or asthma. <i>Arthritis Care Res </i>(Hoboken). 2020 Jan 21. ddoi: 10.1002/ACR.24140.<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/208.pdf"> Download PDF file</a>.</p>
          <p>208. Kragstrup TW, Sohn DH, Lepus CM, Onuma K, Wang Q, Robinson WH, Sokolove J. Fibroblast-like synovial cell production of extra domain A fibronectin associates with inflammation in osteoarthritis.<i> Biochem Biophys Res Commun</i>. 2020 Feb 12;522(3):731-735. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/207.pdf">Download PDF file</a>.</p>
          <p>207. Lee B, Song YS, Rhodes C, Goh TS, Roh JS, Jeong H, Park J, Lee HN, Lee SG, Kim S, Kim M, Lee SI, Sohn DH, Robinson WH.  Protein phosphatase magnesium-dependent 1A induces inflammation in rheumatoid arthritis.<i> Biochem Biophys Res Commun.</i> 2020 Feb 12;522(3):731-735. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/206.pdf">Download PDF file</a>.</p>
        </ul>
        <ul className="publications-list"><b>2010-2019</b>
          <p>206. James JA, Chena H, Young KA, Bemis EA, Seifert J, Bourn RL,  Deane KD, Demoruelle MK, Feser M, O'Dell JR, Weisman MH, Keating RM, Gaffney PM, Kelly JA, Langefeld CD, Harley JB, Robinson WH, Hafler DA, O'Connor KC, Buckner J, Guthridge JM, Norris JM, Holers VM. Latent autoimmunity across disease-specific boundaries in at-risk first-degree relatives of SLE and RA patients. <i>EBioMedicine</i> (2019). 42:76–85. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/205.pdf">Download PDF file</a>.</p>
          <p>205. Zhang F, Wei K, Slowikowsk K, Fonseka CY, Rao DA, Kelly S, Goodman SM, Tabechian D, Hughes LB, Salomon-Escoto K, Watts GFM, Jonsson AH, Rangel-Moreno J, Pellett NM, Rozo C, Apruzzese W, Eisenhaure TM, Lieb DJ, Boyle DL, Mandelin II, AM, Boyce BF, DiCarlo E, Gravallese EM, Gregersen PK, Moreland L, Firestein GS, Hacohen N, Nusbaum C,  Lederer JA, Perlman H, Pitzalis C, Filer A, Holers VM, Bykerk VP, Donlin LT, Anolik JH, Brenner MB, Raychaudhuri S, Albrecht J, Bridges Jr SL, Buckley CD, Buckner JH, Dolan J, Guthridge JM, Gutierrez-Arcelus M, Ivashkiv LB, James EA, James JA, Keegan J, Lee YC, McGeachy MJ, McNamara MA, Mears JR, Mizoguchi F, Nguyen JP, Noma A, Orange DE, Rohani-Pichavant M, Ritchlin C, Robinson WH, Seshadri A, Sutherby D, Seifert J, Turner JD, and Utz PJ. Defining inflammatory cell states in rheumatoid arthritis joint synovial tissues by integrating single-cell transcriptomics and mass cytometry. <i>Nat Immunol</i>. 2019 July ; 20(7): 928–942. doi:10.1038/s41590-019-0378-1. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/204.pdf">Download PDF file</a>.</p>
          <p>204. Zaccardelli A, Liu X, Ford JA, Cui J, Lu B, Chu SH, Schur PH, Speyer CB, Costenbader KH, Robinson WH, Sokolove J, Karlson EW, Camargo Jr CA, Sparks JA. Asthma and elevation of anti-citrullinated protein antibodies prior to the onset of rheumatoid arthritis. <i>Arthritis Research &amp; Therapy,</i> (2019) 21:246. doi: 10.1186/s13075-019-2035-3. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/203.pdf">Download PDF file</a>.</p>
          <p>203. Kwon OC, Choi B, Lee EJ, Park JE, Lee EJ, Kim EY, Kim SM, Shin MK, Kim TH, Hong S, Lee CK, Yoo B, Robinson WH, Kim YG, Chang EJ. Negative Regulation of Osteoclast Commitment by Intracellular Protein Phosphatase Magnesium-Dependent 1A. <i>Arthritis Rheumatol, </i>2019 Nov 24. doi: 10.1002/art.41180. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/203A.pdf">Download PDF file</a>. </p>
          <p>202.<b> </b>Ramadoss NS, Robinson WH. Characterizing the BCR repertoire in immune-mediated diseases.<i> Nat Rev Rheumatol.</i> 2020 Jan 16(1):-08. doi: 10.1038/s41584-019-0339-y.<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/202_Characterizing_the_BCR.pdf"> Download PDF file</a>.</p>
          <p>201. Pang YP, Casal Moura M, Thompson GE, Nelson DR, Hummel AM, Jenne DE, Emerling D, Volkmuth W, Robinson WH, Specks U. Remote Activation of a Latent Epitope in an Autoantigen Decoded With Simulated B-Factors. <a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/31708920"><i>Front Immunol.</i></a> 2019 Oct 25;10:2467. doi: 10.3389/fimmu.2019.02467. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/201.pdf">Download PDF file</a>.</p>
          <p>200. Murphy KA, Bhamidipati K, Rubin SJS, Kipp L, Robinson WH, Lanz TV. Immunomodulatory receptors are differentially expressed in B and T cell subsets relevant to autoimmune disease. <a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/31669582"><i>Clin Immunol.</i></a> 2019 Oct 25;209:108276. doi: 10.1016/j. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/200--Immunomodulatory-receptors-are-differentially-expressed-in-B-and-T-cell-subsets-relevant-to-autoimmune-disease.pdf">Download PDF file</a>.</p>
          <p>199. Wang Q, Onuma K, Liu C, Wong H, Bloom MS, Elliott EE, Cao RR, Hu N, Lingampalli N, Sharpe O, Zhao X, Sohn DH, Lepus CM, Sokolove J, Mao R, Cisar CT, Raghu H, Chu CR, Giori NJ, Willingham SB, Prohaska SS, Cheng Z, Weissman IL, Robinson WH. Dysregulated integrin αVβ3 and CD47 signaling promotes joint inflammation, cartilage breakdown, and progression of osteoarthritis. <i>JCI Insight</i>. 2019 Sep 19;4(18). pii: 128616. doi: 10.1172  <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/199--Dysregulated-integrin-V-3-and-CD47-signaling-promotes-joint-inflammation-cartilage-breakdown-and-progression-of-osteoarthritis.pdf">Download PDF file</a>.</p>
          <p>198. Martinez-Prat L, Palterer B, Vitiello G, Parronchi P, Robinson WH, Mahler M. Autoantibodies to protein-arginine deiminase (PAD) 4 in rheumatoid arthritis: immunological and clinical significance, and potential for precision medicine. <i>Expert Rev Clin Immunol</i>. 2019 Oct;15(10):1073-1087. doi: 10.1080/1744666X.2020.1668778. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/198-Autoantibodies-to-protein-arginine-deiminase-PAD-4-in-rheumatoid-arthritis-immunological-and-clinical-significance-and-potential-for-precision-medicine.pdf">Download PDF file</a>.</p>
          <p>197. Byrd AS, Carmona-Rivera C, O'Neil LJ, Carlucci PM, Cisar C, Rosenberg AZ, Kerns ML, Caffrey JA, Milner SM, Sacks JM, Aliu O, Broderick KP, Reichner JS, Miller LS, Kang S, Robinson WH, Okoye GA, Kaplan MJ. Neutrophil extracellular traps, B cells, and type I interferons contribute to immune dysregulation in hidradenitis suppurativa. <i>Sci Transl Med</i>. 2019 Sep 4;11(508). pii: eaav5908. doi: 10.1126/scitranslmed.aav5908. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/197--Neutrophil-extracellular-traps-B-cells-and-type-I-interferons-contribute-to-immune-dysregulation-in-hidradenitis-suppurativa.pdf">Download PDF file</a>.</p>
          <p>196. Steinman L, Ho PP, Robinson WH, Utz PJ, Villoslada P. Antigen-specific tolerance to self-antigens in protein replacement therapy, gene therapy and autoimmunity. Curr Opin Immunol. 2019 Aug 30;61:46-53. doi: 10.1016/j.coi.2019.07.011. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/196--Antigen-specifictolerancetoself-antigensinproteinreplacementtherapy-genetherapyandautoimmunity.pdf">Download PDF file</a>.</p>
          <p> 195. Lippner EA, Lewis DB, Robinson WH, Katsumoto TR. Paraneoplastic and Therapy-Related Immune Complications in Thymic Malignancies. <i>Curr Treat Options Oncol</i>. 2019 Jun 22;20(7):62. doi: 10.1007/s11864-019-0661-2/. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/195--Paraneoplastic-and-therapy-related-immune-complication-in-thymic-malignancies.pdf">Download PDF file</a>.</p>
          <p>194. Wang Q, Lepus CM, Raghu H, Reber LL, Tsai MM, Wong HH, von Kaeppler E, Lingampalli N, Bloom MS, Hu N, Elliott EE, Olivero F, Punzi L, Giori NJ, Goodman SB, Chu CR, Sokolove J, Fukuoka Y, Schwartz LB, Galli SJ, Robinson WH. IgE-mediated mast cell activation promotes inflammation and cartilage destruction in osteoarthritis. <i>eLife</i>. 2019 May 14. doi: 10.7554/eLife.39905.<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/194.pdf"> Download PDF file.</a></p>
          <p>193. Rubin SJS, Bloom MS, Robinson WH. B cell checkpoints in autoimmune rheumatic diseases. <i><a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/30967621#" title="Nature reviews. Rheumatology.">Nat Rev Rheumatol</a>, </i>2019 Apr 9, doi: 10.1038/s41584-019-0211-0.<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/193.pdf"> Download PDF file</a>.<br/>
          </p>
          <p>192. Orange DE, Agius P, DiCarlo EF, Mirza S, Pannellini T, Szymonifka J, Jiang CS, Figgie MP, Frank MO, Robinson WH, Donlin LT, Rozo C, Gravallese EM, Bykerk VP, Goodman SM. Histologic and transcriptional evidence of subclinical synovial inflammation in rheumatoid arthritis patients in clinical remission. Arthritis Rheumatol. 2019 Mar 5. doi: 10.1002/art.40878. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/192.pdf">Download PDF file</a>.</p>
          <p>191. Kongpachith S, Lingampalli N, Ju CH, Blum LK, Lu DR, Elliott SE, Mao R, Robinson WH. Affinity Maturation of the Anti-Citrullinated Protein Antibody Paratope Drives Epitope Spreading and Polyreactivity in Rheumatoid Arthritis. Arthritis Rheumatol. 2019 Feb 27. doi: 10.1002/art.40760. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/191.pdf">Download PDF file</a>.</p>
          <p>190. Sweatt AJ, Hedlin HK, Balasubramanian V, Hsi A, Blum LK, Robinson WH, Haddad F, Hickey PM, Condliffe RA, Lawrie A, Nicolls MR, Rabinovitch M, Khatri P, Zamanian RT. Discovery of Distinct Immune Phenotypes Using Machine Learning in Pulmonary Arterial Hypertension. <i>Circ Res</i>. 2019 Jan 21. doi: 10.1161/CIRCRESAHA.118.313911. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/190.pdf">Download PDF file</a>.</p>
          <p>189. Nigrovic LE, Lipsett SC, Molins CR, Wormser GP, Bennett JE, Garro AC, Levas MN, Balamuth F, Neville D, Lingampalli N, Robinson WH, Branda JA. Higher C6 enzyme immunoassay index values correlate with a diagnosis of noncutaneous Lyme disease. <i>Diagn Microbiol Infect Dis</i>. 2018 Dec 8. pii: S0732-8893(18)30719-3. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/189.pdf">Download PDF file</a>.</p>
          <p>188. Schutzer SE, Body BA, Boyle J, Branson BM, Dattwyler RJ, Fikrig E, Gerald NJ, Gomes-Solecki M, Kintrup M, Ledizet M, Levin AE, Lewinski M, Liotta LA, Marques A, Mead PS, Mongodin EF, Pillai S, Rao P, Robinson WH, Roth KM, Schriefer ME, Slezak T, Snyder JL, Steere AC, Witkowski J, Wong SJ, Branda JA. Direct Diagnostic Tests for Lyme Disease. <i>Clin Infect Dis.</i> 2019 Mar 5;68(6):1052-1057. doi: 10.1093/cid/ciy614. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/188.pdf">Download PDF file</a>.</p>
          <p>187. Ho PP, Lahey LJ, Mourkioti F, Kraft PE, Filareto A, Brandt M, Magnusson KEG, Finn EE, Chamberlain JS, Robinson WH, Blau HM, Steinman L. Engineered DNA plasmid reduces immunity to dystrophin while improving muscle force in a model of gene therapy of Duchenne dystrophy. Proc Natl Acad Sci U S A. 2018 Sep 25;115(39):E9182-E9191. doi: 10.1073/pnas.1808648115. Epub 2018 Sep 4. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/187.pdf">Download PDF file</a>.</p>
          <p>186. Holers VM, Demoruelle MK, Kuhn KA, Buckner JH, Robinson WH, Okamoto Y, Norris JM, Deane KD. Rheumatoid arthritis and the mucosal origins hypothesis: protection turns to destruction. <i>Nat Rev Rheumatol.</i> 2018 Sep;14(9):542-557. doi: 10.1038/s41584-018-0070-0. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/186.pdf">Download PDF file</a>.</p>
          <p>185. Blum, L. K., Adamska, J. Z., Martin, D. S., Rebman, A. W., Elliott, S. E., Cao, R. R. L., Embers, M. E., Aucott, J. N., Soloski, M. J. and Robinson, W. H. (2018) Robust B Cell Responses Predict Rapid Resolution of Lyme Disease. <i>Frontiers in Immunology 9</i>. 10.3389/fimmu.2018.01634. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/185.pdf">Download PDF file</a>.</p>
          <p>
              184. Zhao L, Yamaguchi Y, Ge X, Robinson WH, Morser J, Leung LLK. Chemerin 156F, generated by chymase cleavage of prochemerin, is elevated in joint fluids of arthritis patients. <i>Arthritis Res The</i>r. 2018 Jul 4;20(1):132. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/zhou_art_res_ther_18.pdf">Download PDF File</a>.
          </p>
          <p>
              183. Hughes-Austin JM, Deane KD, Giles JT, Derber LA, Zerbe GO, Dabelea DM, Sokolove J, Robinson WH, Holers VM, Norris JM. Plasma adiponectin levels are associated with circulating inflammatory cytokines in autoantibody positive first-degree relatives of rheumatoid arthritis patients. <i>PLoS One</i>. 2018 Jun 25;13(6):e019957. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/hughes_austin_PLoS_One_18.pdf">Download PDF File</a>.
          </p>
          <p>182. Elliott SE, Kongpachith S, Lingampalli N, Adamska JZ, Cannon BJ, Mao R, Blum LK, Robinson WH. Affinity Maturation Drives Epitope Spreading and Generation of Pro-inflammatory Anti-Citrullinated Protein Antibodies in Rheumatoid Arthritis. <i>Arthritis Rheumatol</i>. 2018 Jun 21. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/182.pdf">Download PDF file</a>.</p>
          <p>181. Lu DR, McDavid AN, Kongpachith S, Lingampalli N, Glanville J, Ju CH, Gottardo R, Robinson WH. T cell-dependent affinity maturation and innate immune pathways differentially drive autoreactive B cell responses in rheumatoid arthritis. <i>Arthritis Rheumatol</i>. 2018 May 31. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/181.pdf">Download PDF file</a>.</p>
          <p>180. Lingampalli N, Sokolove J, Lahey LJ, Edison JD, Gilliland WR, Michael Holers V, Deane KD, Robinson WH. Combination of anti-citrullinated protein antibodies and rheumatoid factor is associated with increased systemic inflammatory mediators and more rapid progression from preclinical to clinical rheumatoid arthritis. Clin Immunol. 2018 May 26. pii: S1521-6616(18)30246-8. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/180.pdf">Download PDF file</a>.</p>
          <p>179. Spence A, Purtha W, Tam J, Dong S, Kim Y, Ju CH, Sterling T, Nakayama M, Robinson WH, Bluestone JA, Anderson MS, Tang Q. Revealing the specificity of regulatory T cells in murine autoimmune diabetes. Proc Natl Acad Sci U S A. 2018 May 15;115(20):5265-5270. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/spence_PNAS_USA_18.pdf">Download PDF file.</a></p>
          <p>178. Carmona-Rivera C, Bicker KL, Thompson PR, Buckner JH, Robinson WH, Fox DA, Kaplan MJ Response to comment on "Synovial fibroblast-neutrophil interactions promote pathogenic adaptive immunity in rheumatoid arthritis" Sci Immunol. 2018 Mar 30;3(21). <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/178.pdf"> Download PDF file</a>.</p>
          <p>
              177. Orange DE, Agius P, DiCarlo EF, Robine N, Geiger H, Szymonifka J, McNamara M, Cummings R, Andersen KM, Mirza S, Figgie M, Ivashkiv LB, Pernis AB, Jiang CS, Frank MO, Darnell RB, Lingampali N, Robinson WH, Gravallese E; Accelerating Medicines Partnership in Rheumatoid Arthritis and Lupus Network, Bykerk VP, Goodman SM, Donlin LT. Identification of Three Rheumatoid Arthritis Disease Subtypes by Machine Learning Integration of Synovial Histologic Features and RNA Sequencing Data. Arthritis Rheumatol. 2018 May;70(5):690-701. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/orange_arthr_immuno_18.pdf">Download PDF File</a>.
          </p>
          <p>176. Ju CH, Blum LK, Kongpachith S, Lingampalli N, Mao R, Brodin P, Dekker CL, Davis MM, Robinson WH. Plasmablast antibody repertoires in elderly influenza vaccine responders exhibit restricted diversity but increased breadth of binding across influenza strains. Clin Immunol. 2018 Feb 1. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/176.pdf">Download PDF file</a>.</p>
          <p>175. Blum LK, Cao RRL, Sweatt AJ, Bill M, Lahey LJ, Hsi AC, Lee CS, Kongpachith S, Ju CH, Mao R, Wong HH, Nicolls MR, Zamanian RT, Robinson WH. Circulating plasmablasts are elevated and produce pathogenic anti-endothelial cell autoantibodies in idiopathic pulmonary arterial hypertension. Eur J Immunol. 2018 Jan 25. doi: 10.1002/eji.201747460. [Epub ahead of print]. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/175.pdf">Download PDF file</a>.</p>
          <p>
              174. Hughes-Austin JM, Gan RW, Deane KD, Weisman MH, Demoruelle MK, Sokolove J, Robinson WH, Holers VM, Norris JM, Ix JH. Association of Antibodies to Citrullinated Protein Antigens with Blood Pressure in First-Degree Relatives of Rheumatoid Arthritis Patients: The Studies of the Etiology of Rheumatoid Arthritis. Am J Nephrol. 2017;46(6):481-487. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/hughes_austin_am_j_nephrol_17.pdf">Download PDF file</a>.
          </p>
          <p>
              173. Branda JA, Body BA, Boyle J, Branson BM, Dattwyler RJ, Fikrig E, Gerald NJ, Gomes-Solecki M, Kintrup M, Ledizet M, Levin AE, Lewinski M, Liotta LA, Marques A, Mead PS, Mongodin EF, Pillai S, Rao P, Robinson WH, Roth KM, Schriefer ME, Slezak T, Snyder J, Steere AC, Witkowski J, Wong SJ, Schutzer SE. Advances in Serodiagnostic Testing for Lyme Disease Are at Hand. Clin Infect Dis. 2017 Dec 7. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/branda_clin_infect_dis_17.pdf">Download PDF file</a>.
          </p>
          <p>
              172. Xiong G, Lingampalli N, Koltsov JCB, Leung LL, Bhutani N, Robinson WH, Chu CR. Men and Women Differ in the Biochemical Composition of Platelet-Rich Plasma. Am J Sports Med. 2018 Feb;46(2):409-419.<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/xiong_am_j_sports_med_18.pdf"> Download PDF file</a>.
          </p>
          <p>171. DeFalco J, Harbell M, Manning-Bog A, Baia G, Scholz A, Millare B, Sumi M, Zhang D, Chu F, Dowd C, Zuno-Mitchell P, Kim D, Leung Y, Jiang S, Tang X, Williamson KS, Chen X, Carroll SM, Espiritu Santo G, Haaser N, Nguyen N, Giladi E, Minor D, Tan YC, Sokolove JB, Steinman L, Serafini TA, Cavet G, Greenberg NM, Glanville J, Volkmuth W, Emerling DE, Robinson WH. Non-progressing cancer patients have persistent B cell responses expressing shared antibody paratopes that target public tumor antigens. <i>Clin Immunol</i>. 2017 Oct 12. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/171.pdf">Download PDF file</a>.</p>
          <p>
              170. Saito T, Miyagawa K, Chen SY, Tamosiuniene R, Wang L, Sharpe O, Samayoa E, Harada D, Moonen JAJ, Cao A, Chen PI, Hennigs JK, Gu M, Li CG, Leib RD, Li D, Adams CM, Del Rosario PA, Bill M, Haddad F, Montoya JG, Robinson WH, Fantl WJ, Nolan GP, Zamanian RT, Nicolls MR, Chiu CY, Ariza ME, Rabinovitch M. Upregulation of Human Endogenous Retrovirus-K Is Linked to Immunity and Inflammation in Pulmonary Arterial Hypertension. <i>Circulation. </i>2017 Nov 14;136(20):1920-1935. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/saito_circulation_17.pdf">Download PDF file</a>.
          </p>
          <p>
              169. Reber LL, Starkl P, Balbino B, Sibilano R, Gaudenzio N, Rogalla S, Sensarn S, Kang D, Raghu H, Sokolove J, Robinson WH, Contag CH, Tsai M, Galli SJ. The tyrosine kinase inhibitor imatinib mesylate suppresses uric acid crystal-induced acute gouty arthritis in mice. PLoS One. 2017 Oct 5;12(10):e0185704. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/reber_plos_17.pdf">Download PDF file</a>.
          </p>
          <p>
              168. Mikuls TR, Duryee MJ, Rahman R, Anderson DR, Sayles HR, Hollins A, Michaud K, Wolfe F, Thiele GE, Sokolove J, Robinson WH, Lingampalli N, Nicholas AP, Talmon GA, Su K, Zimmerman MC, Klassen LW, Thiele GM. Enrichment of malondialdehyde-acetaldehyde antibody in the rheumatoid arthritis joint. <i>Rheumatology (Oxford)</i>. 2017 Oct 1;56(10):1794-1803. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/mikuls_rheumolatology_17.pdf">Download PDF file</a>.
          </p>
          <p>
              167. Birru Talabi M, Mackey RH, Kuller LH, Dorman JS, Deane KD, Robinson WH, Walitt BT, Chang Y, Holers VM, Liu S, Moreland LW. Human Leukocyte Antigen Shared Epitope and Inflammation, Cardiovascular Disease, Cancer, and Mortality Among Postmenopausal Women in the Women's Health Initiative Rheumatoid Arthritis Study. <i>Am J Epidemiol.</i> 2017 Jul 15;186(2):245-254. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/birrutalabi_am_j_epidemiology_17.pdf">Download PDF file</a>.
          </p>
          <p>166. Nair N, Feng N, Blum LK, Sanyal M, Ding S, Jiang B, Sen A, Morton JM, He XS, Robinson WH, Greenberg HB. VP4- and VP7-specific antibodies mediate heterotypic immunity to rotavirus in humans.<i> Sci Transl Med</i>. 2017 Jun 21;9(395). <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/nair_sci_trans_med_17-2.pdf">Download PDF file</a>.</p>
          <p>
              165.  Carmona-Rivera C, Carlucci PM, Moore E, Lingampalli N, Uchtenhagen H, James E, Liu Y, Bicker KL, Wahamaa H, Hoffmann V, Catrina AI, Thompson P, Buckner JH, Robinson WH, Fox DA, Kaplan MJ. Synovial fibroblast-neutrophil interactions promote pathogenic adaptive immunity in rheumatoid arthritis. <i>Sci Immunol.</i> 2017 Apr;2(10). <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/carmona-rivera_17_sci-immunol.pdf">Download PDF file</a>.
          </p>
          <p>
              164. Willis VC, Banda NK, Cordova KN, Chandra PE, Robinson WH, Cooper DC, Lugo D, Mehta G, Taylor S, Tak PP, Prinjha RK, Lewis HD, Holers VM. Protein arginine deiminase 4 inhibition is sufficient for the amelioration of collagen-induced arthritis. <i>Clin Exp Immunol.</i> 2017 May;188(2):263-274. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/willis_clin_exp_immunol_17pdf.pdf">Download PDF file</a>.
          </p>
          <p>
              163. Lee J, Luria A, Rhodes C, Raghu H, Lingampalli N, Sharpe O, Rada B, Sohn DH, Robinson WH, Sokolove J. Nicotine drives neutrophil extracellular traps formation and accelerates collagen-induced arthritis. <i>Rheumatology (Oxford)</i>. 2017 Apr 1;56(4):644-653. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/lee_rheum_17.pdf">Download PDF file</a>.
          </p>
          <p>
              162. Geraldino-Pardilla L, Russo C, Sokolove J, Robinson WH, Zartoshti A, Van Eyk J, Fert-Bober J, Lima J, Giles JT, Bathon JM. Association of anti-citrullinated protein or peptide antibodies with left ventricular structure and function in rheumatoid arthritis. <i>Rheumatology (Oxford).</i> 2017 Apr 1;56(4):534-540. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/geraldino-pardilla_.rheum_17.pdf">Download PDF file</a>.
          </p>
          <p>
              161. Raghu H, Lepus CM, Wang Q, Wong HH, Lingampalli N, Oliviero F, Punzi L, Giori NJ, Goodman SB, Chu CR, Sokolove JB, Robinson WH. CCL2/CCR2, but not CCL5/CCR5, mediates monocyte recruitment, inflammation and cartilage destruction in osteoarthritis. <i>Ann Rheum Dis</i>. 2017 May;76(5):914-922. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/raghu_ann_rheum_dis_17.pdf">Download PDF file</a>.
          </p>
          <p>160. Tedeschi SK, Cui J, Arkema EV, Robinson WH, Sokolove J, Lingampalli N, Sparks JA, Karlson EW, Costenbader KH. Elevated BMI and antibodies to citrullinated proteins interact to increase rheumatoid arthritis risk and shorten time to diagnosis: A nested case-control study of women in the Nurses' Health Studies. <i>Semin Arthritis Rheum</i>. 2016 Sep 13. [Epub ahead of print]. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/160.pdf">Download PDF file</a>.</p>
          <p>
              159. Abrams GD, Luria A, Sampson J, Madding RA, Robinson WH, Safran MR, Sokolove J. Decreased Synovial Inflammation in Atraumatic Hip Microinstability Compared With Femoroacetabular Impingement. <i>Arthroscopy. </i>2017 Mar;33(3):553-558. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/abrams_arthrosopcy_17.pdf">Download PDF file</a>.
          </p>
          <p>
              158. Tajerian M, Hung V, Khan H, Lahey LJ, Sun Y, Birklein F, Krämer HH, Kra, Kingery WS, Clark JD. Identification of KRT16 as a target of an autoantibody response in complex regional pain syndrome. <i>Exp Neurol. </i>2016 Oct 20;287(Pt 1):14-20. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/tejerian_exp_neurol_16.pdf">Download PDF file</a>.
          </p>
          <p>157. Geraldino-Pardilla L, Giles JT, Sokolove J, Zartoshti A, Robinson WH, Budoff M, Detrano R, Bokhari S, Bathon JM. Association of Anti-Citrullinated Peptide Antibodies with Coronary Artery Calcification in Rheumatoid Arthritis. <i>Arthritis Care Res (Hoboken)</i>. 2016 Oct 1. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/157.pdf">Download PDF file</a>.</p>
          <p>
              156. Robinson WH, Lepus CM, Wang Q, Raghu H, Mao R, Lindstrom TM, Sokolove J. Low-grade inflammation as a key mediator of the pathogenesis of osteoarthritis. <i>Nat Rev Rheumatol.</i> 2016 Oct;12(10):580-92. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/robinson_nat_rev_rheum_16.pdf">Download PDF file.</a>
          </p>
          <p>155. Sokolove J, Wagner CA, Lahey LJ, Sayles H, Duryee MJ, Reimold AM, Kerr G, Robinson WH, Cannon GW, Thiele GM, Mikuls TR. Increased inflammation and disease activity among current cigarette smokers with rheumatoid arthritis: a cross-sectional analysis of US veterans. <i>Rheumatology (Oxford)</i>. 2016 Jul 31.<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/sokolove_rheum_16.pdf"> Download PDF file</a>.</p>
          <p>154. Aucott JN, Soloski MJ, Rebman AW, Crowder LA, Lahey LJ, Wagner CA, Robinson WH, Bechtold KT. CCL19 as a Chemokine Risk Factor for Posttreatment Lyme Disease Syndrome: a Prospective Clinical Cohort Study.<i>Clin Vaccine Immunol.</i>2016 Sep 6;23(9):757-66.<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/aucott_clin_vacc_imm_16.pdf">Download PDF file</a>.</p>
          <p>
              153. Regules JA, Cicatelli SB, Bennett JW, Paolino KM, Twomey PS, Moon JE, Kathcart AK, Hauns KD, Komisar JL, Qabar AN, Davidson SA, Dutta S, Griffith ME, Magee CD, Wojnarski M, Livezey JR, Kress AT, Waterman PE, Jongert E, Wille-Reece U, Volkmuth W, Emerling D, Robinson WH, Lievens M, Morelle D, Lee CK, Yassin-Rajkumar B, Weltzin R, Cohen J, Paris RM, Waters NC, Birkett AJ, Kaslow DC, Ballou WR, Ockenhouse CF, Vekemans J. Fractional Third and Fourth Dose of RTS,S/AS01 Malaria Candidate Vaccine: A Phase 2a Controlled Human Malaria Parasite Infection and Immunogenicity Study. <i>J Infect Dis. </i>2016 Sep 1;214(5):762-71. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/regules_j_inf_dis_16.pdf">Download PDF file</a>.
          </p>
          <p>
              152. Kinslow JD, Blum LK, Deane KD, Demoruelle MK, Okamoto Y, Parish MC, Kongpachith S, Lahey LJ, Norris JM, Robinson WH, Holers VM. Elevated IgA Plasmablast Levels in Subjects at Risk of Developing Rheumatoid Arthritis. <i>Arthritis Rheumatol. </i>2016 Oct;68(10):2372-83. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/kinslow_arthri_rheum_16.pdf">Download PDF file</a>.
          </p>
          <p>
              151. England BR, Sokolove J, Robinson WH, Thiele GM, Ganti AK, Sayles H, Michaud K, Caplan L, Davis LA, Cannon GW, Sauer B, Singh N, Blair Solow E, Reimold AM, Kerr GS, Schwab P, Baker JF, Mikuls TR. Associations of Circulating Cytokines and Chemokines With Cancer Mortality in Men With Rheumatoid Arthritis. <i>Arthritis Rheumatol.</i> 2016 Oct;68(10):2394-402. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/england_arthrit_rheum_16.pdf">Download PDF file</a>.
          </p>
          <p>
              150. Yu C, Burns JC, Robinson WH, Utz PJ, Ho PP, Steinman L, Frey AB. Identification of Candidate Tolerogenic CD8(+) T Cell Epitopes for Therapy of Type 1 Diabetes in the NOD Mouse Model. <i>J Diabetes Res</i>. 2016;2016:9083103. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/yu_j_diabetes_res_16.pdf">Download PDF file</a>.
          </p>
          <p>
              149. Cambridge G, Leandro MJ, Lahey LJ, Fairhead T, Robinson WH, Sokolove J. B cell depletion with rituximab in patients with rheumatoid arthritis: Multiplex bead array reveals the kinetics of IgG and IgA antibodies to citrullinated antigens. <i>J Autoimmun.</i> 2016 Jun;70:22-30. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/cambridge_j_autoimm_16.pdf">Download PDF file</a>.
          </p>
          <p>
              148. Kim JH, Furrow E, Ritt MG, Utz PJ, Robinson WH, Yu L, Eckert A, Stuebner K, O'Brien TD, Steinman L, Modiano JF. Anti-Insulin Immune Responses Are Detectable in Dogs with Spontaneous Diabetes. <i>PLoS One</i>. 2016 Mar 31;11(3):e0152397. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/kim_PLoS_One_16.pdf">Download PDF file</a>.
          </p>
          <p>
              147. Kragstrup TW, Greisen SR, Nielsen MA, Rhodes C, Stengaard-Pedersen K, Hetland ML, Hørslev-Petersen K, Junker P, Østergaard M, Hvid M, Vorup-Jensen T, Robinson WH, Sokolove J, Deleuran B. The interleukin-20 receptor axis in early rheumatoid arthritis: novel links between disease-associated autoantibodies and radiographic progression. <i>Arthritis Res Ther</i>. 2016 Mar 11;18:61. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/kragstrup_artritis_res_ther_16.pdf">Download PDF file</a>.
          </p>
          <p>
              146. Sajda T, Hazelton J, Patel M, Seiffert-Sinha K, Steinman L, Robinson W, Haab BB, Sinha AA. Multiplexed autoantigen microarrays identify HLA as a key driver of anti-desmoglein and -non-desmoglein reactivities in pemphigus. <i>Proc Natl Acad Sci U S A</i>. 2016 113(7):1859-64. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/sajda_PNAS_USA_16.pdf">Download PDF file</a>.
          </p>
          <p>145. Abrams GD, Luria A, Carr RA, Rhodes C, Robinson WH, Sokolove J. Association of synovial inflammation and inflammatory mediators with glenohumeral rotator cuff pathology. 2016 <i>J Shoulder Elbow Surg</i>. 2016 Jan 14. pii: S1058-2746(15)00582-0. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/145.pdf">Download PDF file</a>.</p>
          <p>
              144. Robinson WH, Mao R. Biomarkers to guide clinical therapeutics in rheumatology? 2016. <i>Curr Opin Rheumato</i>l. 2016 Mar;28(2):168-75. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/abrams_curr_opin_rheumatol_16.pdf">Download PDF file</a>.
          </p>
          <p>143. Corsiero E, Bombardieri M, Carlotti E, Pratesi F, Robinson W, Migliorini P, Pitzalis C. Single cell cloning and recombinant monoclonal antibodies generation from RA synovial B cells reveal frequent targeting of citrullinated histones of NETs. 2015. Ann Rheum Dis. pii: annrheumdis-2015-208356. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/143.pdf">Download PDF file</a>.</p>
          <p>142. Wampler Muskardin T, Vashisht P, Dorschner JM, Jensen MA, Chrabot BS, Kern M, Curtis JR, Danila MI, Cofield SS, Shadick N, Nigrovic PA, St Clair EW, Bingham CO 3rd, Furie R, Robinson W, Genovese M, Striebich CC, O'Dell JR, Thiele GM, Moreland LW, Levesque M, Bridges SL Jr, Gregersen PK, Niewold TB. Increased pretreatment serum IFN-β/α ratio predicts non-response to tumour necrosis factor α inhibition in rheumatoid arthritis. 2015. <i>Ann Rheum Dis</i>. pii: annrheumdis-2015-208001. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/142.pdf">Download PDF file</a>.</p>
          <p>
              141. Ligocki AJ, Rivas JR, Rounds WH, Guzman AA, Li M, Spadaro M, Lahey L, Chen D, Henson PM, Graves D, Greenberg BM, Frohman EM, Ward ES, Robinson W, Meinl E, White CL 3rd, Stowe AM, Monson NL. A Distinct Class of Antibodies May Be an Indicator of Gray Matter Autoimmunity in Early and Established Relapsing Remitting Multiple Sclerosis Patients. 2015. <i>ASN Neuro</i>. 7(5). <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/ligocki_asn_neuro_15.pdf">Download PDF file</a>.
          </p>
          <p>140. Lahey LJ, Panas MW, Mao R, Delanoy M, Flanagan J, Binder SR, Rebman AW, Montoya JG, Soloski MJ, Steere AC, Dattwyler RJ, Arnaboldi PM, Aucott JN, Robinson WH. Development of a multi-antigen panel for improved detection of Borrelia burgdorferi infection in early Lyme disease. 2015. <i>J Clin Microbiol</i>. pii: JCM.02111-15. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/140A.pdf">Download PDF file</a>.</p>
          <p>
              139. Robinson WH, Mao R. Decade in review-technology: Technological advances transforming rheumatology. 2015. <i>Nat Rev Rheumato</i>l. doi: 10.1038/nrrheum.2015.137. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/robinson_nature_rev_15.pdf">Download PDF file</a>.
          </p>
          <p>138. Toivola DM, Habtezion A, Misiorek JO, Zhang L, Nyström JH, Sharpe O, Robinson WH, Kwan R, Omary MB. Absence of keratin 8 or 18 promotes antimitochondrial autoantibody formation in aging male mice. 2015. <i>FASEB J</i>. pii: fj.14-269795. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/138.pdf">Download PDF f</a><a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/138.pdf">ile</a>.</p>
          <p>137. Sokolove J, Schiff M, Fleischmann R, Weinblatt ME, Connolly SE, Johnsen A, Zhu J, Maldonado MA, Patel S, Robinson WH. Impact of baseline anti-cyclic citrullinated peptide-2 antibody concentration on efficacy outcomes following treatment with subcutaneous abatacept or adalimumab: 2-year results from the AMPLE trial. 2015. <i>Ann Rheum Dis</i>. pii: annrheumdis-2015-207942. doi: 10.1136/annrheumdis-2015-207942. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/137.pdf">Download PDF file</a>.</p>
          <p>135. Huang QQ, Perlman H, Birkett R, Doyle R, Fang D, Haines GK, Robinson W, Datta S, Huang Z, Li QZ, Phee H, Pope RM. CD11c-mediated deletion of Flip promotes autoreactivity and inflammatory arthritis. 2015. <i>Nat Commun.</i> 6:7086. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/huang_nature_comm_15.pdf">Download PDF file</a>.</p>
          <p>
              136. Hyun Sohn D, Rhodes C, Onuma K, Zhao X, Sharpe O, Gazitt T, Shiao R, Fert-Bober J, Cheng D, Lahey LJ, Wong HH, Van Eyk J, Robinson WH, Sokolove J. Local joint inflammation and histone citrullination provides a murine model for the transition from preclinical autoimmunity to inflammatory arthritis. 2015. <i>Arthritis Rheumatol</i>. 67(11):2877-87. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/sohn_arthritis_and_rheum_15.pdf">Download PDF file</a>.
          </p>
          <p>
              134. Mackey RH, Kuller LH, Deane KD, Walitt BT, Chang YF, Holers VM, Robinson WH, Tracy RP, Hlatky MA, Eaton CB, Liu S, Freiberg MS, Talabi MB, Schelbert EB, Moreland LW. Rheumatoid Arthritis, Anti-Cyclic Citrullinated Peptide Positivity, and Cardiovascular Disease Risk in the Women's Health Initiative. 2015. <i>Arthritis Rheumatol.</i> 67(9):2311-22. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/mackey_arthr_and_rheum_15.pdf">Download PDF file</a>.
          </p>
          <p>
              133. Solow EB, Yu F, Thiele GM, Sokolove J, Robinson WH, Pruhs ZM, Michaud KD, Erickson AR, Sayles H, Kerr GS, Gaffo AL, Caplan L, Davis LA, Cannon GW, Reimold AM, Baker J, Schwab P, Anderson DR, Mikuls TR. Vascular calcifications on hand radiographs in rheumatoid arthritis and associations with autoantibodies, cardiovascular risk factors and mortality. 2015. <i>Rheumatology (Oxford).</i> 54(9):1587-95. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/solow_rheum_15.pdf">Download PDF file</a>.
          </p>
          <p>
              132. Hansmann L, Blum L, Ju CH, Liedtke M, Robinson WH, Davis MM. Mass cytometry analysis shows that a novel memory phenotype B cell is expanded in multiple myeloma. 2015. <i>Cancer Immunol Res.</i> 3(6):650-60. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/hansmann_cancer_imm_res_15.pdf">Downlaod PDF file</a>.
          </p>
          <p>131. Robinson WH. Sequencing Functional Antibody Repertoires - Diagnostic and Therapeutic Discovery, <i>Nature Reviews Rheum</i>. 2015 11(3):171-182. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/robinson_NRR_14.pdf">Download PDF file</a>.</p>
          <p>
              130. Thiele GM, Duryee MJ, Anderson DR, Klassen LW, Mohring SM, Young KA, Benissan-Messan D, Sayles H, Dusad A, Hunter CD, Sokolove J, Robinson WH, O'Dell JR, Nicholas AP, Tuma DJ, Mikuls TR. <i>Arthritis Rheumatism</i>. 2015 67(3):645-55. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/thiele_AandR_15.pdf">Download PDF file</a>.
          </p>
          <p>
              129. Davis ML, LeVan TD, Yu F, Sayles H, Sokolove J, Robinson W, Michaud K, Thiele GM, Mikuls TR. Associations of toll-like receptor (TLR)-4 single nucleotide polymorphisms and rheumatoid arthritis disease progression: An observational cohort study. <i>Int Immunopharmacol.</i> 2015 24(2):346-52. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/davis_Int_Immunopharm_15.pdf">Download PDF file</a>.
          </p>
          <p>
              128. Reber LL, Marichal T, Sokolove J, Starkl P, Gaudenzio N, Iwakura Y, Karasuyama H, Schwartz LB, Robinson WH, Tsai M, Galli SJ. Mast cell-derived IL-1? contributes to uric acid crystal-induced acute arthritis in mice. 2014. <i>Arthritis Rheumatol</i>. 2014. 66(10):2881-91. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/reber_Arthritis_and_Rheumatology_14.pdf">Download PDF file</a>.
          </p>
          <p>
              127. Kim YG, Sohn DH, Zhao X, Sokolove J, Lindstrom TM, Yoo B, Lee CK, Reveille JD, Taurog JD, Robinson WH. Role of PPM1A and anti-PPM1A Autoantibodies in Ankylosing Spondylitis. <i>Arthritis Rheumatol.</i> 2014, 66(10):2793-803. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/kim_Arthritis_and_Rheumatology_14.pdf">Download PDF file</a>.
          </p>
          <p>
              126. Gonzalez SM, Payne JB, Yu F, Thiele GM, Erickson AR, Johnson PG, Schmid MJ, Cannon GW, Kerr GS, Reimold AM, Sokolove J, Robinson WH, Mikuls TR. Alveolar Bone Loss is Associated with Circulating Anti-Citrullinated Protein Antibody (ACPA) in Rheumatoid Arthritis Patients. J. Periodontol. 2014 Oct 9:1-16. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/gonzales_J_Periodontol_14.pdf">Download PDF file</a>.
          </p>
          <p>
              125. Lu DR, Robinson WH. Street-experienced peripheral B cells traffic to the brain. <i>Sci Transl Med</i>. 6(248):248fs31. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/Lu_Robinson_STM_2014.pdf">Download PDF File</a>.
          </p>
          <p>124. Tan YC, Kongpachith S, Blum LK, Ju CH, Lahey LJ, Lu DR, Cai X, Wagner CA, Lindstrom TM, Sokolove J, Robinson WH. Barcode-Enabled Sequencing of Plasmablast Antibody Repertoires in Rheumatoid Arthritis. 2014. <i>Arthritis Rheumatol.</i> 66(10) :2706-2715<i>. </i><a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/tan_A_and_R_14.pdf">Download PDF file</a><i>.</i></p>
          <p>
              123. Reber LL, Marichal T, Sokolove J, Starkl P, Gaudenzio N, Iwakura Y, Karasuyama H, Schwartz LB, Robinson WH, Tsai M, Galli SJ. Mast cell-derived IL-1Β contributes to uric acid crystal-induced acute arthritis in mice. 2014.<i> Arthritis Rheumatol. </i> 2014. 66(10):2881-91. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/reber_Arthritis_and_Rheumatology_14.pdf">Download PDF file</a>.
          </p>
          <p>
              122. Shelef MA, Sokolove J, Robinson WH, Huttenlocher A. Reply. 2014 <i>Arthritis Rheumatol.</i> 2014. 66(9):2644-5. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/shelef_RESPONSE-Arthritis_and_Rheumatology_14.pdf">Download PDF file</a>.
          </p>
          <p>
              121. Mikuls TR, Payne JB, Yu F, Thiele GM, Reynolds RJ, Cannon GW, Markt J, McGowan D, Kerr GS, Redman RS, Reimold A, Griffiths G, Beatty M, Gonzalez SM, Bergman DA, Hamilton BC 3rd, Erickson AR, Sokolove J, Robinson WH, Walker C, Chandad F, O'Dell JR. Periodontitis and Porphyromonas gingivalis in patients with rheumatoid arthritis. <i>Arthritis Rheumatol.</i> 66(5):1090-100. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/gingivalis_a_and_r_14.pdf">Download PDF File</a>.
          </p>
          <p>
              120. Sokolove J, Johnson DS, Lahey LJ, Wagner CA, Cheng D, Thiele GM, Michaud K, Sayles H, Reimold AM, Caplan L, Cannon GW, Kerr G, Mikuls TR, Robinson WH. Rheumatoid factor as a potentiator of anti-citrullinated protein antibody-mediated inflammation in rheumatoid arthritis. <i>Arthritis Rheumatol.</i> 2014 Apr;66(4):813-21. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/sokolove_ar_14.pdf">Download PDF File</a>.
          </p>
          <p>
              119. Soloski MJ, Crowder LA, Lahey LJ, Wagner CA, Robinson WH, Aucott JN. Serum inflammatory mediators as markers of human lyme disease activity.<i> PLoS One. </i>2014 Apr 16;9(4):e93243. eCollection 2014. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/soloski_PLoSOne_14.pdf">Download PDF File</a>.
          </p>
          <p>118. Lu DR, Tan YC, Kongpachith S, Cai X, Stein EA, Lindstrom TM, Sokolove J, Robinson WH. Identifying functional anti-Staphylococcus aureus antibodies by sequencing antibody repertoires of patient plasmablasts. <i>Clin Immunol. </i>2014 Mar 1;152(1-2):77-89. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/118.pdf">Download PDF File</a>.</p>
          <p>117. Oei EH, van Tiel J, Robinson WH, Gold GE. Quantitative radiological imaging techniques for articular cartilage composition: Towards early diagnosis and development of disease-modifying therapeutics for osteoarthritis. Arthritis Care Res (Hoboken). 2014. 66(8):1129-114.1 <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/oei_Arthritis_Care_and_Research_14.pdf">Download PDF file</a>.</p>
          <p>
              116. Kuller LH, Mackey RH, Walitt BT, Deane KD, Holers VM, Robinson WH, Sokolove J, Chang Y, Liu S, Parks CG, Wright NC, Moreland LW. Determinants of mortality among postmenopausal women in the women's health initiative who report rheumatoid arthritis. <i>Arthritis Rheumatol.</i> 2014 Mar;66(3):497-507. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/kuller_a_and_r_14.pdf">Download PDF File</a>.
          </p>
          <p>
              115. Tan YC, Blum LK, Kongpachith S, Ju CH, Cai X, Lindstrom TM, Sokolove J, Robinson WH. High-throughput sequencing of natively paired antibody chains provides evidence for original antigenic sin shaping the antibody response to influenza vaccination. <i>Clin Immunol.</i> 2014 Mar;151(1):55-65. Epub 2014 Jan 9. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/tan_clin_imm_14.pdf">Download PDF File</a>.
          </p>
          <p>114. Kragstrup TW, Jalilian B, Hvid M, Kjærgaard A, Ostgård R, Schiøttz-Christensen B, Jurik AG, Robinson WH, Vorup-Jensen T, Deleuran B.Decreased plasma levels of soluble CD18 link leukocyte infiltration with disease activity in spondyloarthritis. <i>Arthritis Res Ther.</i> 2014 Feb 4;16(1):R42. [Epub ahead of print]. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/kragstrup_art_14.pdf">Download PDF File</a>.</p>
          <p>113. Wang Q, Pan X, Wong HH, Wagner CA, Lahey LJ, Robinson WH, Sokolove J. Oral and topical boswellic acid attenuates mouse osteoarthritis.<i> Osteoarthritis Cartilage. </i>2014 22(1):128-32. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/113.pdf">Download PDF File</a>.</p>
          <p>112. Shelef MA, Sokolove J, Lahey LJ, Wagner CA, Sackmann EK, Warner TF, Wang Y, Beebe DJ, Robinson WH, Huttenlocher A. Peptidylarginine deiminase 4 contributes to tumor necrosis factor alpha induced inflammatory arthritis. <i>Arthritis Rheumatol. </i>2014 Feb 4. [Epub ahead of print].<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/112.pdf"> Download PDF File</a>. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/shelef_RESPONSE-Arthritis_and_Rheumatology_14.pdf">Download Response to Letter</a>.</p>
          <p>111. Wagner CA, Sokolove J, Lahey LJ, Bengtsson C, Saevarsdottir S, Alfredsson L, Delanoy M, Lindstrom TM, Walker RP, Bromberg R, Chandra PE, Binder SR, Klareskog L, Robinson WH. Identification of anticitrullinated protein antibody reactivities in a subset of anti-CCP-negative rheumatoid arthritis: association with cigarette smoking and HLA-DRB1 'shared epitope' alleles. <i>Ann Rheum Dis.</i> 2014 Jan 16. [Epub ahead of print] <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/111.pdf">Download PDF File</a>.</p>      
          <p>110. Price JV, Haddon DJ, Kemmer D, Delepine G, Mandelbaum G, Jarrell JA, Gupta R, Balboni I, Chakravarty EF, Sokolove J, Shum AK, Anderson MS, Cheng MH, Robinson WH, Browne SK, Holland SM, Baechler EC, Utz PJ. Protein microarray analysis reveals BAFF-binding autoantibodies in systemic lupus erythematosus. <i>J Clin Invest.</i> 2013 Dec 2;123(12):5135-45. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/110.pdf">Download PDF File</a>.</p>
          <p>
              109. Wang Q, Pan X, Wong HH, Wagner CA, Lahey LJ, Robinson WH, Sokolove J. Oral and topical boswellic acid attenuates mouse osteoarthritis. <i>Osteoarthritis Cartilage.</i> 2013 22:128-132. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/wang_oa_cartil_13.pdf">Download PDF File</a>.
          </p>
          <p>108. Gottlieb P, Utz PJ, Robinson W, Steinman L. Clinical optimization of antigen specific modulation of type 1 diabetes with the plasmid DNA platform. <i>Clin Immunol.</i> 2013 Dec;149(3):297-306. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/108.pdf">Download PDF File</a>.</p>
          <p>
              107. Lepus CM, Song JJ, Wang Q, Wagner CA, Lindstrom TM, Chu CR, Sokolove J, Leung LL, Robinson WH. Carboxypeptidase B serves as a protective mediator in osteoarthritis. Arthritis Rheum. 2013 66(1):101-106. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/lepus_art_rheu_14.pdf">Download PDF File</a>.
          </p>
          <p>
              106. Lindstrom TM, Robinson WH. Autoimmune Attack Takes Your Breath Away. <i>Sci Transl Med</i>. 2013 Oct 9;5(206):206fs36. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/lindstrom_stm_focis_13.pdf">Download PDF File</a>.
          </p>
          <p>105. Roep BO, Solvason N, Gottlieb PA, Abreu JR, Harrison LC, Eisenbarth GS, Yu L, Leviten M, Hagopian WA, Buse JB, von Herrath M, Quan J, King RS, Robinson WH, Utz PJ, Garren H; BHT-3021 Investigators, Steinman L. Plasmid-encoded proinsulin preserves C-peptide while specifically reducing proinsulin-specific CD8? T cells in type 1 diabetes. <i>Sci Transl Med.</i> 2013 5(191):191ra82. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/105.pdf">Download PDF File</a>. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/roep_STM__suppl_mat_13.pdf">Download supplementary material</a>.</p>
          <p>
              104. Young KA, Deane KD, Derber LA, Hughes-Austin JM, Wagner CA, Sokolove J, Weisman MH, Buckner JH, Mikuls TR, O'Dell JR, Keating RM, Gregersen PK, Robinson WH, Holers VM, Norris JM. Relatives without rheumatoid arthritis show reactivity to anti-citrullinated protein/peptide antibodies that are associated with arthritis-related traits: studies of the etiology of rheumatoid arthritis. <i>Arthritis Rheum.</i> 2013 65(8):1995-2004. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/young_a_and_r_2013.pdf">Download PDF file</a>.
          </p>
          <p>103. Giles JT, Danoff SK, Sokolove J, Wagner CA, Winchester R, Pappas DA, Siegelman S, Connors G, Robinson WH, Bathon JM. Association of fine specificity and repertoire expansion of anticitrullinated peptide antibodies with rheumatoid arthritis associated interstitial lung disease. <i>Ann Rheum Dis</i>. 2013 May 28. [Epub ahead of print] <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/103.pdf">Download PDF file</a>.</p>
          <p>102. Van Haren K, Tomooka BH, Kidd BA, Banwell B, Bar-Or A, Chitnis T, Tenembaum SN, Pohl D, Rostasy K, Dale RC, O'Connor KC, Hafler DA, Steinman L, Robinson WH. Serum autoantibodies to myelin peptides distinguish acute disseminated encephalomyelitis from relapsing- remitting multiple sclerosis. <i>Mult Scler.</i> 2013 Apr 23. [Epub ahead of print] <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/102.pdf">Download PDF File</a>.</p>
          <p>96. Hughes-Austin JM, Deane KD, Derber LA, Kolfenbach JR, Zerbe GO, Sokolove J, Lahey LJ, Weisman MH, Buckner JH, Mikuls TR, O'Dell JR, Keating RM, Gregersen PK, Robinson WH, Holers VM, Norris JM. Multiple cytokines and chemokines are associated with rheumatoid arthritis-related autoimmunity in first-degree relatives without rheumatoid arthritis: Studies of the Aetiology of Rheumatoid Arthritis (SERA). <i>Annals Rheumatic Disease</i>. 2012 Aug 21. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/96.pdf">Download PDF file</a>.</p>
          <p>95. Tang H, Lee M, Sharpe O, Salamone L, Noonan EJ, Hoang CD, Levine S, Robinson WH, Shrager JB. Oxidative stress-responsive microRNA-320 regulates glycolysis in diverse biological systems. <i>FASEB J.</i> 2012. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/95.pdf">Downlaod PDF file</a>.</p>
          <p>94. Mikuls TR, Thiele GM, Deane KD, Payne JB, O'Dell JR, Yu F, Sayles H, Weisman MH, Gregersen PK, Buckner JH, Keating RM, Derber LA, Robinson WH, Michael Holers V, Norris JM. Porphyromonas gingivalis and disease-related autoantibodies in individuals at increased risk for future rheumatoid arthritis. <i>Arthritis Rheum</i>. 2012 <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/mikuls_a_and_r_12.pdf">Download PDF file</a>.</p>
          <p>93. Ho PP, Kanter JL, Johnson AM, Srinagesh HK, Chang EJ, Purdy TM, van Haren K, Wikoff WR, Kind T, Khademi M, Matloff LY, Narayana S, Hur EM, Lindstrom TM, He Z, Fiehn O, Olsson T, Han X, Han MH, Steinman L, Robinson WH. Identification of naturally occurring fatty acids of the myelin sheath that resolve neuroinflammation. <i>Science Translational Medicine 2012</i>, 4(137):137ra73. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/93.pdf">Download PDF File</a>.</p>
          <p>92. Sokolove J, Bromberg R, Deane KD, Lahey LJ, Derber LA, Chandra PE, Edison JD, Gilliland WR, Tibshirani RJ, Norris JM, Holers VM, Robinson WH. Autoantibody epitope spreading in the pre-clinical phase predicts progression to rheumatoid arthritis.<i> PLoS One</i>. 2012;7(5):e35296. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/92.pdf">Download PDF File</a>.</p>
          <p>85. Lin JT, Stein EA, Wong MT, Kalpathy KJ, Su LL, Utz PJ, Robinson WH, Fathman CG. Differential mTOR and ERK pathway utilization by effector CD4 T cells suggests combinatorial drug therapy of arthritis. <i>Clin. Immunol. </i>142(2):127-38, 2012. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/85.pdf">Download PDF file</a>.</p>
          <p>83. Zhao L, Yamaguchi Y, Sharif S, Du XY, Lee DM, Recht LD, Robinson WH, Song JJ, Morser J, Leung LL. Chemerin 158K is the dominant chemerin isoform in synovial and cerebrospinal fluids but not in plasma. <i>J Biol Chem</i>. 2011. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/83.pdf">Download PDF file</a>.</p>
          <p>
              101. Sokolove J, Brennan MJ, Sharpe O, Lahey LJ, Kao AH, Krishnan E, Edmundowicz D, Lepus CM, Wasko MC, Robinson WH. Brief report: citrullination within the atherosclerotic plaque: a potential target for the anti-citrullinated protein antibody response in rheumatoid arthritis. <i>Arthritis Rheum</i>. 2013 65(7):1719-24. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/sokolove__a_and_r_13.pdf">Download PDF file</a>.
          </p>
          <p>
              100. Keith RC, Sokolove J, Edelman BL, Lahey L, Redente EF, Holers VM, Sakaguchi S, Robinson WH, Riches DW. Testosterone is protective in the sexually dimorphic development of arthritis and lung disease in SKG mice. <i>Arthritis Rheum.</i> 2013 65:1487-93. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/keith_a_and_r_13.pdf">Download PDF File</a>.
          </p>
          <p>
              99. Robinson WH, Lindstrom TM, Cheung RK, Sokolove J. Mechanistic biomarkers for clinical decision making in rheumatic diseases. <i>Nat Rev Rheumato</i>l. 2013. 9(5):267-76. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/robinson_Nat_rev_rheum_13.pdf">Download PDF File</a>.
          </p>
          <p>
              98. Tavares Da Silva F, De Keyser F, Lambert PH, Robinson WH, Westhovens R, Sindic C. Optimal approaches to data collection and analysis of potential immune mediated disorders in clinical trials of new vaccines. <i>Vaccine</i>. 2013 Apr 3;31(14):1870-6. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/tavares_Vaccine_13.pdf">Download PDF file</a>.
          </p>
          <p>
              97. Cloutier N, Tan S, Boudreau LH, Cramb C, Subbaiah R, Lahey L, Albert A, Shnayder R, Gobezie R, Nigrovic PA, Farndale RW, Robinson WH, Brisson A, Lee DM, Boilard E. The exposure of autoantigens by microparticles underlies the formation of potent inflammatory components: the microparticle-associated immune complexes. <i>EMBO Mol Med</i>. 2013 Feb;5(2):235-49. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/cloutier_EMBO_13.pdf">Download PDF file</a>.
          </p>
          <p>
              91. Maecker HT, Lindstrom TM, Robinson WH, Utz PJ, Hale M, Boyd SD, Shen-Orr SS, Fathman CG. New tools for classification and monitoring of autoimmune diseases. <i>Nat Rev Rheumatol.</i> 2012 May 31;8(6):317-28. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/maecker_nat_rev_rheum_12.pdf">Download PDF file</a>.
          </p>
          <p>
              90. Swanson CD, Akama-Garren EH, Stein EA, Petralia JD, Ruiz PJ, Edalati A, Lindstrom TM, Robinson WH. Inhibition of epidermal growth factor receptor tyrosine kinase ameliorates collagen-induced arthritis. <i>J Immunol. </i>188(7):3513-21, 2012. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/swanson_j_immuno_12.pdf">Download PDF file</a>.
          </p>
          <p>
              89. Zhao X, Qureshi F, Eastman PS, Manning WC, Alexander C, Robinson WH, Hesterberg LK. Pre-analytical effects of blood sampling and handling in quantitative immunoassays for rheumatoid arthritis. <i>J Immunol Methods.</i> 2012. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/zhao_j_immuno_meth_12.pdf">Download PDF file</a>.
          </p>
          <p>
              88. Rothbard JB, Kurnellas MP, Brownell S, Adams CM, Su L, Axtell RC, Chen R, Fathman CG, Robinson WH, Steinman L. Therapeutic effects of systemic administration of the chaperone alpha B crystallin associated with binding proinflammatory plasma proteins.<i> J Biol Chem</i>. Feb 3, 2012. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/rothbard_j_biol_chem_12.pdf">Download PDF file</a>.
          </p>
          <p>
              87. Hwang SJ, Choi B, Kang SS, Chang JH, Kim YG, Chung YH, Sohn DH, So MW, Lee CK, Robinson WH, Chang EJ. Interleukin-34 produced by human fibroblast-like synovial cells in rheumatoid arthritis supports osteoclastogenesis. <i>Arthritis Res Ther.</i> 14(1):R14, 2012. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/hwang_arth_res_ther_12.pdf">Download PDF file</a>.
          </p>
          <p>
              86. Sohn DH, Sokolove J, Sharpe O, Erhart JC, Chandra PE, Lahey LJ, Lindstrom TM, Hwang I, Boyer KA, Andriacchi TP, Robinson WH. Plasma proteins present in osteoarthritic synovial fluid can stimulate cytokine production via Toll-like receptor 4. <i>Arthritis Res Ther</i>. 14(1):R7, 2012. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/sohn_arth_res_ther_12.pdf">Download PDF file</a>.
          </p>
          <p>
              84. Wang Q, Rozelle AL, Lepus CM, Scanzello CR, Song JJ, Larsen DM, Crish JF, Bebek G, Ritter SY, Lindstrom TM, Hwang I, Wong HH, Punzi L, Encarnacion A, Shamloo M, Goodman SB, Wyss-Coray T, Goldring SR, Banda NK, Thurman JM, Gobezie R, Crow MK, Holers VM, Lee DM, Robinson WH. Identification of a central role for complement in osteoarthritis. <i>Nature Medicine</i>. 2011.<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/wang_nat_med_2011.pdf"> Download PDF file</a>.
          </p>
          <p>
            <i>Science</i> News Spot. The New View of Complement. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/Science-2012-Leslie-1034-7.pdf">Download PDF file</a>.
          </p>
          <p>
              82. Crespo O, Kang SC, Daneman R, Lindstrom TM, Ho PP, Sobel RA, Steinman L, Robinson WH. Tyrosine Kinase Inhibitors Ameliorate Autoimmune Encephalomyelitis in a Mouse Model of Multiple Sclerosis. <i>J Clin Immunol.</i> 2011. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/crespo_j_clin_imm_2011.pdf">Download PDF file</a>.
          </p>
          <p>
              81. Chang BY, Huang MM, Francesco M, Chen J, Sokolove J, Magadala P, Robinson WH, Buggy JJ. The Bruton tyrosine kinase inhibitor PCI-32765 ameliorates autoimmune arthritis by inhibition of multiple effector cells. Arthritis Res Ther. 13(4):R115. 2011. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/chang_art_2011.pdf">Download PDF file</a>.
          </p>
          <p>
              80. Chandra PE, Sokolove J, Hipp BG, Lindstrom TM, Elder JT, Reveille JD, Eberl H, Klause U, Robinson WH. Novel multiplex technology for diagnostic characterization of rheumatoid arthritis. <i> Arthritis Res Ther</i>. 13(3):R102. 2011. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/chandra_art_2011.pdf">Download_PDF_file</a>.
          </p>
          <p>
              79. Song JJ, Hwang I, Cho KH, Garcia MA, Kim AJ, Wang TH, Lindstrom TM, Lee AT, Nishimura T, Zhao L, Morser J, Nesheim M, Goodman SB, Lee DM, Bridges SL Jr, Gregersen PK, Leung LL, Robinson WH. Plasma carboxypeptidase B downregulates inflammatory responses in autoimmune arthritis. <i>J. Clinical Investigation</i>. 121(9):3517-27, 2011. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/song_j_clin_invest_2011.pdf">Downlaod PDF file</a>.
          </p>
          <p>
              78. Sokolove J, Lindstrom TM, Robinson WH. Development and deployment of antigen arrays for investigation of B-cell fine specificity in autoimmune disease. <i>Frontiers in Bioscience</i> <i>(Elite Ed.)</i>, 4:320-30. 2012. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/sokolove_fronteirs_bioscience_12.pdf">Download PDF file</a>.
          </p>
          <p>
              77. Robinson WH, Steinman, L. Human peptidome display. <i>Nature Biotechnology</i>. 29(6):499-501. 2011. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/robinson_nature_biotech_11.pdf">Download PDF file</a>.
          </p>
          <p>
              76. Pearl JI, Ma T, Irani AR, Huang Z, Robinson WH, Smith RL, Goodman SB. Role of the Toll-like receptor pathway in the recognition of orthopedic implant wear-debris particles. <i>Biomaterials</i>. 32(24):5535-42. 2011. PMID: 21592562, <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/pearl_biomaterials_11.pdf">Download PDF file</a>.
          </p>
          <p>
              75. Rothbard JB, Zhao X, Sharpe O, Strohman MJ, Kurnellas M, Mellins ED, Robinson WH, Steinman L. Chaperone activity of α B-crystallin is responsible for its incorrect assignment as an autoantigen in multiple sclerosis. <i>J Immunology</i>. 186(7):4263-8, 2011. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/rothbard_j_immuno_11.pdf">Download PDF file</a>.
          </p>
          <p>
              74. Willis VC, Gizinski AM, Banda NK, Causey CP, Knuckley B, Cordova KN, Luo Y, Levitt B, Glogowska M, Chandra P, Kulik L, Robinson WH, Arend WP, Thompson PR, Holers VM. N-α-Benzoyl-N5-(2-chloro-1-iminoethyl)-L-ornithine amide, a protein arginine deiminase inhibitor, reduces the severity of murine collagen-induced arthritis. J Immunology. 186(7):4396-404, 2011. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/willis_j_immuno_11.pdf">Download PDF file</a>.
          </p>
          <p>
              73. Lindstrom TM, Robinson WH. Fishing for biomarkers with antigen mimics. <i>Cell</i>, 7;144(1):13-5, 2011. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/lindstrom_cell_11.pdf">Download PDF file</a>.
          </p>
          <p>
              72. Sokolove J, Zhao X, Chandra PE, Robinson WH. Immune complexes containing citrullinated fibrinogen co-stimulate macrophages via toll-like receptor 4 and Fcγ recepor. <i>Arthritis Rheum.</i> 63(1):53-62, 2011. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/sokolove_arth_rheum_11.pdf">Download PDF file</a>.
          </p>
          <p>
              71. Lindstrom TM, Robinson WH. A multitude of kinases--which are the best targets in treating rheumatoid arthritis? <i>Rheum Dis Clin North Am.</i> 36(2):367-83. PMID: 20510239, 2010. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/lindstrom_rheum_dis_clin_no_am_10.pdf">Download PDF file</a>.
          </p>
          <p>
              70. Lindstrom TM, Robinson WH. Biomarkers for rheumatoid arthritis: making it personal. <i>Scand J Clin Lab Invest </i>Suppl. 242:79-84. PMID: 20515283, 2010. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/lindstrom_scan_j_clin_lab_invest_10.pdf">Download PDF file</a>.
          </p>
          <p>
              69. Vargas ME, Watanabe J, Singh SJ, Robinson WH, Barres BA. Endogenous antibodies promote rapid myelin clearance and effective axon regeneration after nerve injury. <i>Proc Natl Acad Sci U S A</i>. 107(26):11993-8. PMID: 20547838, 2010. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/vargas_pnas_2010.pdf">Download PDF file</a>.
          </p>
          <p>
              68. Deane KD, O'Donnell CI, Hueber W, Majka DS, Lazar AA, Derber LA, Gilliland WR, Edison JD, Norris JM, Robinson WH, Holers VM. The number of elevated cytokines/chemokines in pre-clinical seropositive rheumatoid arthritis predicts time to diagnosis in an age-dependent manner. The number of elevated cytokines/chemokines in pre-clinical seropositive rheumatoid arthritis predicts time to diagnosis in an age-dependent manner. <i>Arthritis Rheum.</i> 62(11):3161-72, 2010. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/deane_arth_rheum_10.pdf">Download PDF file</a>.
          </p>
          <p>67. Söderström K, Stein E, Colmenero P, Purath U, Müller-Ladner U, de Matos CT, Tarner IH, Robinson WH, Engleman EG. Natural killer cells trigger osteoclastogenesis and bone destruction in arthritis. <i>Proc Natl Acad Sci U S A.</i> 107(29):13028-33, 2010. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/soderstrom_pnas_2010.pdf">Download PDF file</a>.</p>
          <p>
              66. Paniagua RT, Fiorentino DF, Chung LS, Robinson WH. Tyrosine kinases in inflammatory dermatologic disease. <i>J. Amer. Acad. Dermatology</i>, [Epub ahead of print]PMID: 20584561, 2010. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/paniagua_j_am_acad_derm_10.pdf">Download PDF file</a>.
          </p>
          <p>
              65. Linstrom TM, and Robinson WH. Rheumatoid arthritis: a consequence of immunosenescence? <i>J. Amer. Geriatrics Soc.</i>, 58(8):1565-75, 2010. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/lindstrom_j_am_ger_soc_10.pdf">Download PDF File</a>.
          </p>
          <p>
              64. Paniagua RT, Chang A, Mariano M, Stein EA, Wang Q, Lindstrom TM, Sharpe O, Roscow C, Ho PP, Lee DM, Robinson WH. c-Fms-mediated differentiation and priming of monocyte lineage cells plays a central role in autoimmune arthritis. <i>Arthritis Res Ther.</i> 2010 Feb 24;12(1):R32. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/paniagua_ARandT_10.pdf">Download PDF file</a>.
          </p>
          <p>
              63. Ho PP, Lee LY, Zhao X, Tomooka BH, Paniagua RT, Sharpe O, BenBarrak MJ, Hueber W, Steinman L, Robinson WH. Autoimmunity against fibrinogen mediates inflammatory arthritis in mice.<i> J. Immunology</i>. 184:379-90, 2010. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/ho_j_immono_10.pdf">Download PDF file</a>.<br/> <br/> 62. Maecker HT, McCoy JP Jr; FOCIS Human Immunophenotyping Consortium, Amos M, Elliott J, Gaigalas A, Wang L, Aranda R, Banchereau J, Boshoff C, Braun J, Korin Y, Reed E, Cho J, Hafler D, Davis M, Fathman CG, Robinson W, Denny T, Weinhold K, Desai B, Diamond B, Gregersen P, Di Meglio P, Nestle FO, Peakman M, Villanova F, Ferbas J, Field E, Kantor A, Kawabata T, Komocsar W, Lotze M, Nepom J, Ochs H, O'Lone R, Phippard D, Plevy S, Rich S, Roederer M, Rotrosen D, Yeh JH. A model for harmonizing flow cytometry in clinical trials. <i>Nat Immunol</i>. 11(11):975-8, 2010. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/maecker-nature-imm-10.pdf">Download PDF file</a>.
          </p>
        </ul>
        <ul className="publications-list"><b>Before 2010</b>
          <p>
              61. Austin C, Hare D, Rozelle AL, *Robinson WH*, Grimm R, Doble P. Elemental bio-imaging of calcium phosphate crystal deposits in knee samples from arthritic patients. <i>Metallomics</i>. 1(2):142-7, 2009. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/austin_metabolomics_09.pdf">Download PDF file</a>.
          </p>
          <p>
              60. Sirota M, Schaub MA, Batzoglou S, Robinson WH, Butte AJ. Autoimmune disease classification by inverse association with SNP alleles. PLoS Genet. 5(12):e1000792, 2009. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/sirota_plos_genetics_10.pdf">Dowbload PDF file</a>.
          </p>
          <p>
              59. Sharif SA, Du X, Myles T, Song JJ, Price E, Lee DM, Goodman SB, Nagashima M, Morser J, Robinson WH, Leung LL. Thrombin-activatable carboxypeptidase B cleavage of osteopontin regulates neutrophil survival and synoviocyte binding in rheumatoid arthritis. <i>Arthritis Rheum</i>. 60(10):2902-12, 2009. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/sharif_aandr_09.pdf">Download PDF file</a>.
          </p>
          <p>
              58. Swansan CD, Lindstrom TM, Paniagua RT, Robinson WH. Tyrosine kinases as targets for the treatment of rheumatoid arthritis. <i>Nature Reviews Rheumatology</i>, 5(6):317-24, 2009. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/swansan_nat_rev_rheum_09.pdf">Download PDF file</a>.
          </p>
          <p>
              57. Hueber W, Tomooka BH, Batliwalla F, Li W, Monach PA, Tibshirani RJ, Van Vollenhoven RF, Lampa J, Saito K, Tanaka Y, Genovese MC, Klareskog L, Gregersen PK, Robinson WH. Blood autoantibody and cytokine profiles predict response to anti-tumor necrosis factor therapy in rheumatoid arthritis. <i>Arthritis Res Ther.</i> 11(3):R76, 2009. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/hueber_tnf_response_art__09.pdf">Download PDF file</a>.
          </p>
          <p>
              56. Monach PA, Hueber W, Kessler B, Tomooka BH, Benbarak M, Simmons BP, Wright J, Thornhill TS, Monestier M, Ploegh H, Robinson WH, Mathis D, Benoist C. A broad screen for targets of immune complexes decorating arthritic joints highlights deposition of nucleosomes in rheumatoid arthritis. <i>Proc Natl Acad Sci U S A.</i> 106(37):15867-72, 2009. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/monach_pnas_09.pdf">Download PDF file</a>.
          </p>
          <p>
              55. Britschgi M, Olin CE, Johns HT, Takeda-Uchimura Y, LeMieux MC, Rufibach K, Rajadas J, Zhang H, Tomooka B, Robinson WH, Clark CM, Fagan AM, Galasko DR, Holtzman DM, Jutel M, Kaye JA, Lemere CA, Leszek J, Li G, Peskind ER, Quinn JF, Yesavage JA, Ghiso JA, Wyss-Coray T. Neuroprotective natural antibodies to assemblies of amyloidogenic peptides decrease with normal aging and advancing Alzheimer's disease. <i>Proc Natl Acad Sci U S A.</i> 106(29):12145-50, 2009. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/britschgi_pnas_09.pdf">Download PDF file</a>.
          </p>
          <p>
              54. Coffey GP, Rajapaksa R, Liu R, Sharpe O, Kuo CC, Krauss SW, Sagi Y, Davis RE, Staudt LM, Sharman JP, Robinson WH, Levy S. Engagement of CD81 induces ezrin tyrosine phosphorylation and its cellular redistribution with filamentous actin. <i>J Cell Sci</i>. 122(Pt 17):3137-44, 2009. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/coffey_j_cell_sci_09.pdf">Download PDF file</a>.
          </p>
          <p>
              53. Small TN, Robinson WH, Miklos DB. B cells and transplantation: an educational resource. <i>Biol Blood Marrow Transplant</i>. 15(1 Suppl):104-13, 2009. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/small_biology_blood_marrow_trpl_09.pdf">Download PDF file</a>.
          </p>
          <p>
              52. Chung L, Fiorentino DF, BenBarak MJ, Adler AS, Mariano MM, Paniagua RT, Milano A, Connolly MK, Ratiner BD, Wiskocil RL, Whitfield ML, Chang HY, Robinson WH. Molecular framework for response to imatinib mesylate in systemic sclerosis. <i>Arthritis &amp; Rheumatism</i>, 60(2):584-591, 2009.<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/chung_arth_rheu_09.pdf"> Download PDF file</a>.
          </p>
          <p>
              51. Li H, Chen W, Zhou Y, Abidi P, Sharpe O, Robinson WH, Kraemer FB, Liu J. Identification of mRNA-binding proteins that regulate the stability of LDL receptor mRNA through AU-rich elements. <i>J Lipid Res</i>. 50(5):820-31, 2009. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/li_j_lipid_res_09.pdf">Download PDF file</a>.
          </p>
          <p>
              50. Kidd BA, Ho PP, Sharpe O, Zhao X, Tomooka BH, Kanter JL, Steinman L, Robinson WH. Epitope spreading to citrullinated antigens in mouse models of autoimmune arthritis and demyelination. <i>Arthritis Res Ther.</i> 2008; 10(5):R119. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/kidd_arthritis_res_08.pdf">Download PDF file</a>.
          </p>
          <p>
              49. Zhao X, Okeke NL, Sharpe O, Batliwalla FM, Lee AT, Ho PP, Tomooka BH, Gregersen PK, Robinson WH. Circulating immune complexes contain citrullinated fibrinogen in rheumatoid arthritis. <i>Arthritis Res Ther. </i>2008; 10(4):R94. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/zhao_arthritis_res_08.pdf">Download PDF file</a>.
          </p>
          <p>
              48. Leung LL, Myles T, Nishimura T, Song JJ, Robinson WH. Regulation of tissue inflammation by thrombin-activatable carboxypeptidase B (or TAFI). <i>Mol Immunol.</i> 2008; 45(16):4080-4083. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/leung_mol_immunol_08.pdf">Download PDF file</a>.
          </p>
          <p>
              47. Shachaf CM, Gentles AJ, Elchuri S, Sahoo D, Soen Y, Sharpe O, Perez OD, Chang M, Mitchel D, Robinson WH, Dill D, Nolan GP, Plevritis SK, Felsher DW. Genomic and proteomic analysis reveals a threshold level of MYC required for tumor maintenance. <i>Cancer Res</i>. 2008; 68(13):5132-5142. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/shachaf_cancer_res_08.pdf">Download PDF file</a>.
          </p>
          <p>
              46. Romero-Sánchez C, WH Robinson, BH Tomooka, J Londoño, R Valle-Oñate, F Huang, X Deng, L Zhang, C Yang, DT Yu. Identification of acute phase reactants and cytokines useful for monitoring infliximab therapy in ankylosing spondylitis. <i>Clin Rheumatol</i> 2008; 27(11):1429-1435. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/romero-sanchez__clinical_rheum_08.pdf">Download PDF file</a>.
          </p>
          <p>
              45. Garren H, Robinson WH, Krasulová E, Havrdová E, Nadj C, Selmaj K, Losy J, Nadj I, Radue EW, Kidd BA, Gianettoni J, Tersini K, Utz PJ, Valone F, Steinman L; BHT-3009 Study Group. Phase 2 trial of a DNA vaccine encoding myelin basic protein for multiple sclerosis.<i> Ann Neurol</i>. 2008; 63(5):611-20. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/garren_08_ann_neurol.pdf">Download PDF file</a>.
          </p>
          <p>
              44. Hill JA, Bell DA, Brintnell W, Yue D, Wehrli B, Jevnikar AM, Lee DM, Hueber W, Robinson WH, Cairns E. Arthritis induced by posttranslationally modified (citrullinated) fibrinogen in DR4-IE transgenic mice.<i> J Exp Med</i>. 2008; 205(4):967-79. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/hill_jem_2008.pdf">Download PDF file</a>
          </p>
          <p>
              43. Kuhn KA, Cozine CL, Tomooka B, Robinson WH, Holers VM. Complement receptor CR2/CR1 deficiency protects mice from collagen-induced arthritis and associates with reduced autoantibodies to type II collagen and citrullinated antigens. <i>Mol Immunol</i>. 2008; 45(10):2808-19. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/kuhn_jci__06.pdf">Download PDF file</a>.
          </p>
          <p>
              42. Han MH, Hwang SI, Roy DB, Lundgren DH, Price JV, Ousman SS, Fernald GH, Gerlitz B, Robinson WH, Baranzini SE, Grinnell BW, Raine CS, Sobel RA, Han DK, Steinman L. Proteomic analysis of active multiple sclerosis lesions reveals therapeutic targets. <i>Nature</i>. 2008; 451(7182):1076-81. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/han_nature_2008.pdf">Download PDF file</a>.
          </p>
          <p>
              41. Ray S, Britschgi M, Herbert C, Takeda-Uchimura Y, Boxer A, Blennow K, Friedman LF, Galasko DR, Jutel M, Karydas A, Kaye JA, Leszek J, Miller BL, Minthon L, Quinn JF, Rabinovici GD, Robinson WH, Sabbagh MN, So YT, Sparks DL, Tabaton M, Tinklenberg J, Yesavage JA, Tibshirani R, Wyss-Coray T. Classification and prediction of clinical Alzheimer's diagnosis based on plasma signaling proteins. <i>Nat Med</i>. 2007; 13(11):1359-62. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/ray_nat_med_2007.pdf">Download PDF file</a>.  
          </p>
          <p>
              40. Bar-Or A, Vollmer T, Antel J, Arnold DL,Bodner CA, Campagnolo D, Gianettoni J, Jalili F,Kachuck N, Lapierre Y, Niino M, Oger J, Price M, Rhodes S, Robinson WH, Shi F-D, Utz PJ, Valone F, Weiner L, Steinman L, Garren H. Induction of Antigen-Specific Tolerance in Multiple Sclerosis After Immunization With DNA Encoding Myelin Basic Protein in a Randomized, Placebo-Controlled Phase 1/2 Trial. <i>Arch. Neurol. </i>2007; 64:E1-E9. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/baror_arch__neurology_07.pdf">Download PDF file</a>.
          </p>
          <p>
              39. Ousman, SS, Tomooka, BH, van Noort JM, Wawrousek EF, O’Connor KC, Hafler DA, Sobel RA, Robinson WH, Steinman L. Protective and therapeutic role for αΒ-crystallin in autoimmune demyelination. <i>Nature.</i> 2007; 448:474-479. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/ousman_nature_07.pdf">Download PDF file</a>.
          </p>
          <p>38. Elchuri S, Naeemuddin M, Sharpe O, Robinson WH, Huang TT. Identification of biomarkers associated with the development of hepatocellular carcinoma in CuZn superoxide dismutase deficient mice. <i>Proteomics. 2007; </i>2121-2129. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/38.pdf">Download PDF file</a>.</p>
          <p>
              37. Paniagua PT, Robinson WH. Imatinib for the treatment of rheumatic diseases. <i>Nature Clinical Practice Rheumatology.</i> 2007; 3(4)190-191. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/paniagua_nat_clin_prac_rheum_07.pdf">Download PDF file</a>.
          </p>
          <p>36. O'Connor KC, McLaughlin KA, De Jager PL, Chitnis T, Bettelli E, Xu C, Robinson WH, Cherry SV, Bar-Or A, Banwell B, Fukaura H, Fukazawa T, Tenembaum S, Wong SJ, Tavakoli NP, Idrissova Z, Viglietta V, Rostasy K, Pohl D, Dale RC, Freedman M, Steinman L, Buckle GJ, Kuchroo VK, Hafle DA, Wucherpfennik KW. Self-antigen tetramers discriminate between myelin autoantibodies to native or denatured protein. <i>Nature Medicine</i>. 2007; 13:211-217. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/oconnor_nat_med_07.pdf">Download PDF file</a>.</p>
          <p>
              35. Hueber, W, Tomooka, BH, Zhao, X, Kidd, BA, Drijfhout, JW, Fries, JF, van Venrooij, WJ, Metzger, AL, Genovese, MC, Robinson, WH. Proteomic analysis of secreted proteins in early rheumatoid arthritis: anti-citrulline autoreactivity is associated with up regulation of proinflammatory cytokines. <i>Ann Rheum Dis.</i> 2007; 66:712–719. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/hueber_ann_rheum_dis_06.pdf">Download PDF File</a>.
          </p>
          <p>
              34.  Ho PP, Higgins JP, Kidd BA, Tomooka B, Digennaro C, Lee LY, de Vegvar HE, Steinman L, Robinson WH.  Tolerizing DNA vaccines for autoimmune arthritis.  <i>Autoimmunity.</i> 2006; 39:675-82. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/ho_autoimmunity_06.pdf">Download PDF file</a>.
          </p>
          <p>
              33. Lopez-Avila V, Sharpe O, Robinson WH. Determination of Ceruloplasmin in Human Serum by SEC-ICPMS. <i>Anal Bioanal Chem.</i> 2006; 386:180-187. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/lopezavila_annal_bioan_chem_06.pdf">Download PDF file</a>.        
          </p>
          <p>
              32. Paniagua RT, Sharpe O, Ho PP, Chan SM, Chang A, Higgins JP, Tomooka BH, Thomas FM, Song JJ, Goodman SB, Lee DM, Genovese MC, Utz PJ, Steinman L, Robinson WH. <i>J Clin Invest. </i>2006; 116:2633-2642. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/paniagua_j_clin_invest_06.pfg.pdf">Download PDF file</a>.
          </p>
          <p>
              31. Hueber W, Robinson WH. Proteomic Biomarkers for Autoimmune Disease. <i>Proteomics. </i>2006; 6:4100-4105. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/hueber_proteomics_06.pdf">Download PDF file</a>.
          </p>
          <p>
              30. Kuhn, KA, Lulik L, Tomooka B, Brashler KJ, Arend WP, Robinson WH, Holers VM. Antibodies against citrullinated proteins enhance tissue injury in experimental autoimmune arthritis. <i>J. Clin. Invest. </i>2006;116:961-73. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/kuhn_jci__06.pdf">Download PDF file</a>
          </p>
          <p>
              29. Robinson, WH. Antigen arrays for antibody profiling, <i>Curr. Opin. Chem. Biol. </i>2006;10:67-72. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/robinson_curr_opin_chem_biol_06.pdf">Download PDF file</a>.
          </p>
          <p>
              28. Kanter JL, Narayana S, Ho PP, Catz I, Warren KG, Sobel RA, Steinman L, Robinson WH. Lipid microarrays identify key mediators of autoimmune brain inflammation. <i>Nat. Med</i>. 2006;12:138-143. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/kanter_nat_med_06.pdf">Download PDF file</a>.
          </p>
          <p>
              27. Steinman L, Utz PJ, Robinson WH. Suppression of autoimmunity via microbial mimics of altered peptide ligands. Curr Top Microbiol Immunol. 2005;296:55-63. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/steinman_curr_top_microbio_imm_05.pdf">Download PDF file</a>.
          </p>
          <p>
              26. Platten M, Ho PP, Youssef S, Fontoura P, Garren H, Hur EM, Gupta R, Lee LY, Kidd BA, Robinson WH, Sobel RA, Selley ML, Steinman L. Treatment of autoimmune neuroinflammation with a synthetic tryptophan metabolite. <i>Science</i>. 2005, 310:850-5. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/platten_science_05.pdf">Download PDF file</a>.
          </p>
          <p>
              25. Ho PP, Fontoura P, Platten M, Sobel RA, DeVoss JJ, Lee LY, Kidd BA, Tomooka BH, Capers J, Agrawal A, Gupta R, Zernik J, Yee MK, Lee BJ, Garren H, Robinson WH, Steinman L. A suppressive oligodeoxynucleotide enhances the efficacy of myelin cocktail/IL-4-tolerizing DNA vaccination and treats autoimmune disease. <i>J Immunol</i>. 2005, 175(9):6226-34. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/ho_j_immuno_05.pdf">Download PDF file</a>.
          </p>
          <p>
              24. Vossenaar ER, Robinson WH. Citrullination and autoimmune disease: 8th Bertine Koperberg meeting. <i> Ann Rheum Dis</i>. 2005, 64(10):1513-5. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/vossenaar_arth_rheu_dis_05.pdf">Download PDF file</a>.
          </p>
          <p>
              23. Hueber W, Kidd BA, Tomooka BH, Lee BJ, Bruce B, Fries JF, Sonderstrup G, Monach P, Drijfhout JW, van Venrooij WJ, Utz PJ, Genovese MC, Robinson WH. Antigen microarray profiling of autoantibodies in rheumatoid arthritis. <i>Arthritis Rheum</i>. 2005, 52(9):2645-55. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/hueber_artritis_rheum_05.pdf">Download PDF file</a>.
          </p>
          <p>
              22. Fontoura P, Ho PP, DeVoss J, Zheng B, Lee BJ, Kidd BA, Garren H, Sobel RA, Robinson WH, Tessier-Lavigne M, Steinman L. Immunity to the extracellular domain of Nogo-A modulates experimental autoimmune encephalomyelitis. <i> J Immunol. </i>2004 Dec 1;173(11):6981-92.<a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/fontoura_j_imm_04.pdf"> Download PDF file</a>.
          </p>
          <p>
              21. Magnano MD, Robinson WH, Genovese, MC. Demyelination and inhibition of tumor necrosis factor (TNF). Clinical and Experimental Rheumatology. 2004. S134-S140. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/magnano_clin_exp_heum_04.pdf">Download PDF file</a>.  
          </p>
          <p>
              20. Graham KL, Robinson WH, Steinman L, Utz PJ. High-throughput methods for measuring autoantibodies in systemic lupus erythematosus and other autoimmune diseases. <i>Autoimmunity</i>. 2004, 37(4):269-72. Review. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/graham_autoimmunity_04.pdf">Download PDF file</a>.
          </p>
          <p>
              19. Hueber W, Zeng D, Sharpe O, Robinson WH, Strober S, Utz PJ. Characterization of novel antigens recognized by serum autoantibodies from anti-CD1 TCR-transgenic lupus mice. <i>Eur J Immunol</i>. 2004, 34(6): 1654-62. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/hueber_eu_j_imm_04.pdf">Download PDF file</a>.
          </p>
          <p>
              18. Neuman De Vegvar HE, Robinson WH. Microarray profiling of antiviral antibodies for the development of diagnostics, vaccines, and therapeutics. <i>Clin Immunol</i>. 2004, 111(2):196-201. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/devegvar_clin_imm_04.pdf">Download PDF file</a>.
          </p>
          <p>
              17. Utz PJ, Genovese MC, Robinson WH. Unlocking the "PAD" lock on rheumatoid arthritis. <i>Ann Rheum Dis.</i> 2004, 63(4):330-2. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/utz_ann_rheum_dis_04.pdf">Download PDF file</a>.
          </p>
          <p>
              16. Hueber W, Utz PJ, Robinson WH. Autoantibodies in early arthritis: advances in diagnosis and prognostication. <i>Clin Exp Rheumatol.</i> 2003, 21(5 Suppl 21): S59-64. Review. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/hueber_clin_exp_rheum_03.pdf">Download PDF file</a>.
          </p>
          <p>
              15. Robinson WH, Utz PJ, Steinman L. Genomic and proteomic analysis of multiple sclerosis. Opinion. <i>Curr Opin Immunol.</i> 2003, 15(6):660-7. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/robinson_curr_opin_imm_03.pdf">Download PDF file</a>.
          </p>
          <p>
              14. Robinson WH, Steinman L, Utz PJ. Protein arrays for autoantibody profiling and fine-specificity mapping. <i>Proteomics.</i> 2003, 3(11):2077-84. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/robinson_proteomics_03.pdf">Download PDF file</a>.
          </p>
          <p>
              13. Neuman De Vegvar HE, Amara RR, Steinman L, Utz PJ, Robinson HL, Robinson WH. Microarray Profiling of Antibody Responses against Simian-Human Immunodeficiency Virus: Postchallenge Convergence of Reactivities Independent of Host Histocompatibility Type and Vaccine Regimen. <i>J Virol.</i> 2003, 15;77(20):11125-11138. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/devegvar_j_vir_03.pdf">Download PDF file</a>.
          </p>
          <p>
              12. Robinson WH, Fontoura P, Lee BJ, De Vegvar HE, Tom J, Pedotti R, DiGennaro CD, Mitchell DJ, Fong D, Ho PP, Ruiz PJ, Maverakis E, Stevens DB, Bernard CC, Martin R, Kuchroo VK, Van Noort JM, Genain CP, Amor S, Olsson T, Utz PJ, Garren H, Steinman L. Protein microarrays guide tolerizing DNA vaccine treatment of autoimmune encephalomyelitis. <i>Nat Biotechnol.</i> 2003, 21(9):1033-1039. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/robinson_nat_biotech_03.pdf">Download PDF file</a>.
          </p>
          <p>
            <i>Nature Biotech News and Views</i>. Eli Sercarz. Arraying autoimmunity treatment. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/SercarzCommentary.pdf">Download PDF file</a>.</p>
          <p>
              11. Robinson WH, Steinman L, Utz PJ. Protein and peptide array analysis of autoimmune disease. <i>Biotechniques</i>. 2002, Suppl:66-9. Review. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/robinson_biotech_02.pdf">Download PDF file</a>.
          </p>
          <p>
              10. Hueber W, Utz PJ, Steinman L, Robinson WH. Autoantibody profiling for the study and treatment of autoimmune disease.<i> Arthritis Res</i>. 2002, 4(5):290-5. Review. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/hueber_arth_res_02.pdf">Download PDF file</a>.
          </p>
          <p>
              9. Robinson WH, Steinman L, Utz PJ. Proteomics technologies for the study of autoimmune disease. <i>Arthritis Rheum</i>. 2002, 46(4):885-93. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/robinson_arth_rheu_02.pdf">Download PDF file</a>.
          </p>
          <p>
              8. Robinson WH, Garren H, Utz PJ, Steinman L. Millennium Award. Proteomics for the development of DNA tolerizing vaccines to treat autoimmune disease. <i>Clin Immunol.</i> 2002, 103(1):7-12. Review. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/robinson_clin_imm_02.pdf">Download PDF file</a>.
          </p>
          <p>
              7. Robinson WH, DiGennaro C, Hueber W, Haab BB, Kamachi M, Dean EJ, Fournel S, Fong D, Genovese MC, de Vegvar HE, Skriner K, Hirschberg DL, Morris RI, Muller S, Pruijn GJ, van Venrooij WJ, Smolen JS, Brown PO, Steinman L, Utz PJ. Autoantigen microarrays for multiplex characterization of autoantibody responses. <i>Nat Med</i>. 2002, 8(3):295-301. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/robinson_nat_med_02.pdf">Download PDF file</a>.
          </p>
          <p>
              6. Robinson WH, Genovese MC, Moreland LW. Demyelinating and neurologic events reported in association with tumor necrosis factor alpha antagonism: by what mechanisms could tumor necrosis factor alpha antagonists improve rheumatoid arthritis but exacerbate multiple sclerosis? <i>Arthritis Rheum.</i> 2001, 44(9):1977-83. <a target="_blank" rel="noopener noreferrer" href="https://med.stanford.edu/content/dam/sm/robinsonlab/documents/robinson_arth_rheu_01.pdf">Download PDF file</a>.
          </p>
        </ul>
        
      </div>
    </Template>
  );
};

export default Publications;