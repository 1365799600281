import React, { useState, useEffect } from "react";
import Template from '../components/Template';
import TeamCard from "../components/TeamCard"; 
import './team.css';
import BillRobinson from '../images/BillRobinson.jpg';

const { REACT_APP_AIRTABLE_API_TOKEN } = process.env;

const Team = () => {
  const [showLabMembers, setShowLabMembers] = useState(true);
  const [showAlumni, setShowAlumni] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [formerData, setFormerData] = useState([]);

  useEffect(() => {
    const fetchCurrentData = async () => {
      try {
        const response = await fetch(
          "https://api.airtable.com/v0/appXqP56p5bXn4bKa/Current%20Team%20Members?sort%5B0%5D%5Bfield%5D=ID&sort%5B0%5D%5Bdirection%5D=asc",
          {
            headers: {
              'Authorization': `Bearer ${REACT_APP_AIRTABLE_API_TOKEN}`,
            },
          }
        );
        const responseData = await response.json();
        setCurrentData(responseData.records);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };
  
    fetchCurrentData(); 

    const fetchFormerData = async () => {
      try {
        const response = await fetch(
          "https://api.airtable.com/v0/appXqP56p5bXn4bKa/Former%20Members",
          {
            headers: {
              'Authorization': `Bearer ${REACT_APP_AIRTABLE_API_TOKEN}`,
            },
          }
        );
        const responseData = await response.json();
        setFormerData(responseData.records);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };
  
    fetchFormerData();
  }, []);

  const renderMembers = () => {
    return (
      <div className="team-page">
        <div className="bill-robinson">
          <div className="image-column">
            <img src={BillRobinson} alt="Bill Robinson" />
          </div>
          <div className="info-column">
            <h3>William H. Robinson, MD PhD</h3>
            <p>James W. Raitt, MD Professor</p>
            <p>Division Chief, Division of Immunology and Rheumatology</p>
            <p><a href="mailto:wrobins@stanford.edu" style={{ textDecoration: 'none', color: '#6e9fc1' }}>wrobins@stanford.edu</a></p>
          </div>
        </div>
        <div className="team-page-people">
          {currentData && currentData.map((records) => (
            <TeamCard
              key={records.id}
              name={records.fields.Name}
              picture={records.fields.Photo[0].url}
              position={records.fields.Position}
              email={records.fields.Email}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderAlumni = () => {
    return (
      <div className="all-alumni">
        <div className="alumni-list">
          <h2 className="alumni-section">Former Postdoctoral Scholars</h2>
          {renderFormerMembers("Former Postdoctoral Scholars")}

          <h2 className="alumni-section">Former Stanford PhD Students</h2>
          {renderFormerMembers("Former Stanford PhD Students")}

          <h2 className="no-bottom-margin alumni-section">Former Research Assistants</h2>
          {renderFormerMembers("Former Research Assistants")}
        </div>
        <h2 className="no-bottom-margin alumni-section">Former Undergraduate Students</h2>
        <h3 className="no-top-margin">(including degrees subsequently earned)</h3>
        {renderFormerMembers("Former Undergraduate Students")}

        <h2 className="alumni-section">Former Staff</h2>
        {renderFormerMembers("Former Staff")}
      </div>
    );
  };

  const renderFormerMembers = (position) => {
    const membersWithPosition = formerData.filter(
      member => member.fields.Position === position
    );
    
    return (
      <ul>
        {membersWithPosition.map((member) => (
          <p key={member.id}>{member.fields.Name}</p>
        ))}
      </ul>
    );
  };

  return (
    <Template>
      <div className="content-container">
        <h1>Meet the Lab</h1>

        <div className="button-container">
          {/* Button to render lab members */}
          <button
            onClick={() => {
              setShowLabMembers(true);
              setShowAlumni(false);
            }}
            className={showLabMembers ? "selected-button" : ""}
          >
            Current Members
          </button>

          {/* Button to render lab alumni */}
          <button
            onClick={() => {
              setShowLabMembers(false);
              setShowAlumni(true);
            }}
            className={showAlumni ? "selected-button" : ""}
          >
            Lab Alumni
          </button>
        </div>

        {/* Conditional rendering based on button clicks */}
        {showLabMembers && renderMembers()}
        {showAlumni && renderAlumni()}
      </div>
    </Template>
  );
};

export default Team;
