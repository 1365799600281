import React, { useEffect, useState } from 'react';
import Template from '../components/Template';
import { ImageGallery } from "react-image-grid-gallery";
import './photos.css';
const { REACT_APP_AIRTABLE_API_TOKEN } = process.env;

export default function Photos() {
  const [data, setData] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.airtable.com/v0/appXqP56p5bXn4bKa/Gallery%20Images",
          {
            headers: {
              'Authorization': `Bearer ${REACT_APP_AIRTABLE_API_TOKEN}`,
            },
          }
        );
        const responseData = await response.json();
        setData(responseData.records);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };
  
    fetchData();
  }, []); 
  
  useEffect(() => {
    const photosContainer = document.querySelector('.photos-container');
  
    if (!photosContainer) return; // If the container is not found, exit early
  
    const images = photosContainer.querySelectorAll('img');
  
    // Options for the IntersectionObserver
    const options = {
      threshold: 0.3 
    };
  
    // Callback function for the IntersectionObserver
    const callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // If the image is intersecting, fade it in
          entry.target.style.transition = 'opacity 1s';
          entry.target.style.opacity = '1';
        } else {
          // If the image is not intersecting, fade it out
          entry.target.style.opacity = '0';
        }
      });
    };
  
    // Create a new IntersectionObserver
    const observer = new IntersectionObserver(callback, options);
  
    // Start observing each image
    images.forEach(image => {
      observer.observe(image);
    });
  
    return () => {
      observer.disconnect();
    };
  }, [data]);

  return (
    <Template>
      <div className="photos-container">
        <ImageGallery
          imagesInfoArray={data.map(item => ({
            alt: item.fields.caption,
            caption: item.fields.Caption,
            src: item.fields.Photo[0].url,
          }))}
          columnWidth={350}
          gapSize={24}
        />
      </div>
    </Template>
  );
}
